import { useState, useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { Link as RouterLink, useNavigate, useLocation } from 'react-router-dom';
import { Form, Formik } from 'formik';
import { Typography, Link, Box, Button } from '@mui/material';

import { Alert } from '@app/ui/alert';
import { Text, Password, yup } from '@app/formik';
import { Loader } from '@app/ui/loader';

import { useAuthActions } from '../hooks/useAuthActions';
import { SignInForm } from '../types/forms';

const validationSchema = yup.object().shape({
  email: yup.string().required().email(),
  password: yup.string().required(),
});

export interface SignInProps {
  readonly isParent: boolean;
}

export const SignIn = ({ isParent }: SignInProps) => {
  const { t } = useTranslation('common');
  const navigate = useNavigate();
  const location = useLocation();
  const state = location.state as { email?: string };

  const { isLoading, signIn } = useAuthActions();
  const [error, setError] = useState<string | undefined>();

  const initialValues = useMemo(
    (): SignInForm => ({
      email: state?.email || '',
      password: '',
    }),
    [state?.email],
  );

  const handleSubmit = useCallback(async (data: SignInForm) => {
    setError(undefined);

    try {
      await signIn(data);
    } catch (e: any) {
      setError(e.code || e.message);
    }
  }, []);

  return (
    <>
      <Loader isLoading={isLoading} />

      <Typography variant="h2" sx={{ mb: 3 }}>
        {t('auth.page.signIn.title')}
      </Typography>
      <Formik onSubmit={handleSubmit} initialValues={initialValues} validationSchema={validationSchema}>
        {() => (
          <Form noValidate>
            <Text required label={t('user.field.email.label')} name="email" type="email" margin="normal" />

            <Password required label={t('user.field.password.label')} name="password" margin="normal" />

            {!!error && (
              <Box mb={2}>
                <Alert severity="error" hideIcon inner>
                  {t(`auth.validation.${error}`, t('auth.validation.credentials'))}
                </Alert>
              </Box>
            )}

            <Box mb={4}>
              <Link
                underline="always"
                color="text.secondary"
                component={RouterLink}
                to={`/password-reset${isParent ? '-parent' : ''}`}
              >
                {t('auth.page.signIn.forgotPasswordLink')}
              </Link>
            </Box>

            <Box
              sx={{
                display: { sm: 'flex' },
                justifyContent: 'space-between',
              }}
            >
              <Button
                sx={{
                  mr: {
                    sm: 1,
                  },
                  mb: {
                    xs: 4,
                    sm: 0,
                  },
                  width: {
                    xs: '100%',
                    sm: 'auto',
                  },
                }}
                type="submit"
                variant="contained"
                color="primary"
              >
                {t('auth.button.signIn')}
              </Button>
              <Button onClick={() => navigate(`/sign-up${isParent ? '-parent' : ''}`)}>
                {t('auth.page.signIn.signUpLink')}
              </Button>
            </Box>
          </Form>
        )}
      </Formik>
    </>
  );
};
