import { useNavigate } from 'react-router-dom';
import { SignUpInfo } from '@app/auth/components/SignUpInfo';
import { LightLayout } from '@app/layouts';
import { Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';

export const WooHooPage = () => {
  const { t } = useTranslation('common');
  const navigate = useNavigate();

  return (
    <LightLayout>
      <SignUpInfo
        title={t('auth.page.signUp.success.title')}
        onClick={() => navigate('/sign-in')}
        buttonText={t('auth.page.signUp.success.button')}
      >
        <Typography variant="subtitle1" paragraph sx={{ mb: 4 }}>
          {t('auth.page.signUp.success.text1')}
        </Typography>
        <Typography variant="subtitle1" paragraph sx={{ mb: 4 }}>
          {t('auth.page.signUp.success.text2')}
        </Typography>
      </SignUpInfo>
    </LightLayout>
  );
};
