import dayJs from 'dayjs';

import { DynamicFormConfigSurvey, DynamicFormElementType, DynamicFormElementWidthMode } from '@app/dynamic';
import { USA_STATES, USA_STATES_WITH_NOT_IN } from '@app/user/constants/usaStates';

import {
  ApplicationPdf,
  ApplicationSurveyEnum,
  CitizenshipStatusEnum,
  heightFtOptions,
  heightInOptions,
  IdentificationTypeEnum,
  RelationshipStatusEnum,
  SexualOrientationEnum,
} from '../constants';
import { COUNTRIES } from '../constants/countries';
import cardImage from '../images/card-application-personal-info.jpg';

export const personalInfo: DynamicFormConfigSurvey<ApplicationSurveyEnum> = {
  name: ApplicationSurveyEnum.personalInfo,
  meta: {
    cardImage,
    dependOnFields: [
      'status',
      'partner.id',
      'user.name',
      'user.id',
      'partner.id',
      'partner.firstName',
      'partner.middleName',
      'partner.lastName',
    ],
  },
  pages: [
    {
      name: `${ApplicationSurveyEnum.personalInfo}.information`,
      information: true,
      elements: [
        {
          name: `${ApplicationSurveyEnum.personalInfo}.information`,
          type: DynamicFormElementType.information,
          widthMode: DynamicFormElementWidthMode.full,
          props: {
            variables: {
              time: '5-10',
            },
            template: ['header', ['description.item1', 'description.item2', 'description.item3']],
          },
        },
      ],
    },
    {
      name: 'name',
      elements: [
        {
          name: 'firstName',
          type: DynamicFormElementType.text,
          meta: {
            pdf: {
              [ApplicationPdf.general]: true,
              [ApplicationPdf.agency]: true,
            },
          },
          props: {
            length: 30,
          },
        },
        {
          name: 'lastName',
          type: DynamicFormElementType.text,
          meta: {
            pdf: {
              [ApplicationPdf.agency]: true,
            },
          },
          props: {
            length: 30,
          },
        },
        {
          name: 'middleName',
          optional: true,
          type: DynamicFormElementType.text,
          meta: {
            pdf: {
              [ApplicationPdf.agency]: true,
            },
          },
          props: {
            length: 30,
          },
        },
      ],
    },
    {
      name: 'address',
      elements: [
        {
          name: 'streetAddress',
          type: DynamicFormElementType.text,
          showDescription: true,
          meta: {
            pdf: {
              [ApplicationPdf.agency]: true,
            },
          },
          props: {
            length: 100,
            multiline: true,
          },
        },
        {
          name: 'city',
          type: DynamicFormElementType.text,
          meta: {
            pdf: {
              [ApplicationPdf.general]: true,
              [ApplicationPdf.agency]: true,
            },
          },
          props: {
            length: 50,
            multiline: true,
          },
        },
        {
          name: 'residency',
          type: DynamicFormElementType.autocomplete,
          meta: {
            pdf: {
              [ApplicationPdf.general]: true,
              [ApplicationPdf.agency]: true,
            },
          },
          props: {
            options: USA_STATES_WITH_NOT_IN,
          },
        },
        {
          name: 'zipCode',
          type: DynamicFormElementType.text,
          meta: {
            pdf: {
              [ApplicationPdf.agency]: true,
            },
          },
          props: {
            numeric: true,
            length: 5,
          },
        },
        {
          name: 'county',
          type: DynamicFormElementType.text,
          showDescription: true,
          meta: {
            pdf: {
              [ApplicationPdf.agency]: true,
            },
          },
          props: {
            length: 20,
          },
        },
      ],
    },
    {
      name: 'phone',
      elements: [
        {
          name: 'phone',
          showDescription: true,
          type: DynamicFormElementType.phone,
          validIf: value => value.length >= 12,
          meta: {
            pdf: {
              [ApplicationPdf.agency]: true,
            },
          },
        },
      ],
    },
    {
      name: 'email',
      elements: [
        {
          name: 'contactEmail',
          alias: 'contactEmail',
          type: DynamicFormElementType.text,
          showDescription: true,
          props: {
            capitalize: false,
          },
        },
      ],
    },
    {
      name: 'dateOfBirth',
      elements: [
        {
          name: 'dateOfBirth',
          type: DynamicFormElementType.date,
          meta: {
            pdf: {
              [ApplicationPdf.general]: true,
              [ApplicationPdf.agency]: true,
            },
          },
          props: {
            openTo: 'year',
            minDate: dayJs().subtract(50, 'years'),
            maxDate: dayJs().subtract(10, 'years'),
            views: ['year', 'month', 'day'],
          },
        },
        {
          name: 'age',
          type: DynamicFormElementType.computedText,
          meta: {
            pdf: {
              [ApplicationPdf.general]: true,
            },
          },
          props: {
            disabled: true,
            formula: ({ dateOfBirth }) => {
              return dateOfBirth && dateOfBirth !== 'Invalid Date'
                ? dayJs().diff(dayJs(dateOfBirth).subtract(1, 'day'), 'y')
                : '-';
            },
          },
        },
      ],
    },
    {
      name: 'bodyMeasurements',
      elements: [
        {
          name: 'heightFt',
          type: DynamicFormElementType.text,
          meta: {
            pdf: {
              [ApplicationPdf.general]: true,
            },
          },
          props: {
            select: true,
            options: heightFtOptions,
          },
        },
        {
          name: 'heightIn',
          type: DynamicFormElementType.text,
          meta: {
            pdf: {
              [ApplicationPdf.general]: true,
            },
          },
          props: {
            select: true,
            options: heightInOptions,
          },
        },
        {
          name: 'weight',
          type: DynamicFormElementType.text,
          meta: {
            pdf: {
              [ApplicationPdf.general]: true,
            },
          },
          props: {
            number: true,
            length: 3,
          },
        },
        {
          name: 'bmi',
          type: DynamicFormElementType.computedText,
          meta: {
            pdf: {
              [ApplicationPdf.general]: true,
            },
          },
          props: {
            disabled: true,
            formula: ({ heightFt, weight, heightIn }) => {
              if ([heightFt, weight, heightIn].some(value => value === undefined || value === null)) {
                return '-';
              }

              return Math.round((weight / (heightFt * 12 + heightIn) ** 2) * 703 * 100) / 100;
            },
          },
        },
      ],
    },
    {
      name: 'countryOfBirth',
      elements: [
        {
          name: 'countryOfBirth',
          showDescription: true,
          type: DynamicFormElementType.autocomplete,
          meta: {
            pdf: {
              [ApplicationPdf.agency]: true,
            },
          },
          props: {
            options: COUNTRIES,
          },
        },
        {
          name: 'ethnicBackground',
          optional: true,
          type: DynamicFormElementType.text,
          meta: {
            pdf: {
              [ApplicationPdf.general]: true,
            },
          },
          props: {
            length: 300,
            multiline: true,
          },
        },
      ],
    },
    {
      name: 'citizenshipStatus',
      elements: [
        {
          name: 'citizenshipStatus',
          type: DynamicFormElementType.enumSelect,
          meta: {
            pdf: {
              [ApplicationPdf.agency]: true,
            },
          },
          props: {
            enum: CitizenshipStatusEnum,
          },
        },
      ],
    },
    {
      name: 'isFinanciallyStable',
      elements: [
        {
          name: 'isFinanciallyStable',
          noLabel: true,
          type: DynamicFormElementType.booleanRadio,
          widthMode: DynamicFormElementWidthMode.full,
          meta: {
            pdf: {
              [ApplicationPdf.general]: true,
            },
          },
        },
      ],
    },
    {
      name: 'isReliableTransportationAvailable',
      elements: [
        {
          name: 'isReliableTransportationAvailable',
          noLabel: true,
          type: DynamicFormElementType.booleanRadio,
          widthMode: DynamicFormElementWidthMode.full,
          meta: {
            pdf: {
              [ApplicationPdf.general]: true,
            },
          },
        },
      ],
    },
    {
      name: 'isGovernmentAssistance',
      elements: [
        {
          noLabel: true,
          name: 'isGovernmentAssistance',
          type: DynamicFormElementType.booleanRadio,
          widthMode: DynamicFormElementWidthMode.full,
          meta: {
            pdf: {
              [ApplicationPdf.general]: true,
            },
          },
        },
        {
          name: 'isGovernmentAssistanceDetails',
          alias: 'pleaseExplain',
          type: DynamicFormElementType.text,
          visibleIf: ({ isGovernmentAssistance }) => isGovernmentAssistance,
          widthMode: DynamicFormElementWidthMode.full,
          meta: {
            pdf: {
              [ApplicationPdf.general]: true,
            },
          },
          props: {
            multiline: true,
            length: 1000,
          },
        },
      ],
    },
    {
      name: 'identificationType',
      elements: [
        {
          name: 'identificationType',
          noLabel: true,
          widthMode: DynamicFormElementWidthMode.full,
          type: DynamicFormElementType.enumRadio,
          meta: {
            pdf: {
              [ApplicationPdf.agency]: true,
            },
          },
          props: {
            enum: IdentificationTypeEnum,
          },
        },
        {
          name: 'identificationNumber',
          visibleIf: ({ identificationType }) =>
            [IdentificationTypeEnum.driverLicense, IdentificationTypeEnum.stateIssuedId].includes(identificationType),
          type: DynamicFormElementType.text,
          widthMode: DynamicFormElementWidthMode.full,
          meta: {
            pdf: {
              [ApplicationPdf.agency]: true,
            },
          },
          props: {
            length: 20,
          },
        },
        {
          name: 'identificationStateOfIssuance',
          visibleIf: ({ identificationType }) =>
            [IdentificationTypeEnum.driverLicense, IdentificationTypeEnum.stateIssuedId].includes(identificationType),
          type: DynamicFormElementType.autocomplete,
          meta: {
            pdf: {
              [ApplicationPdf.agency]: true,
            },
          },
          props: {
            options: USA_STATES,
          },
        },
        {
          name: 'identificationExpiredAt',
          visibleIf: ({ identificationType }) =>
            [IdentificationTypeEnum.driverLicense, IdentificationTypeEnum.stateIssuedId].includes(identificationType),
          type: DynamicFormElementType.date,
          meta: {
            pdf: {
              [ApplicationPdf.agency]: true,
            },
          },
          props: {
            openTo: 'year',
            disablePast: true,
            views: ['year', 'month', 'day'],
          },
        },
      ],
    },
    {
      name: 'socialSecurityNumber',
      elements: [
        {
          name: 'socialSecurityNumber',
          type: DynamicFormElementType.formattedText,
          validIf: value => value.length === 11,
          meta: {
            pdf: {
              [ApplicationPdf.agency]: true,
            },
          },
          props: {
            mask: '000-00-0000',
          },
        },
      ],
    },
    {
      name: 'hasTravelledOutsideUS',
      showDescription: true,
      elements: [
        {
          name: 'hasTravelledOutsideUS',
          noLabel: true,
          type: DynamicFormElementType.booleanRadio,
          meta: {
            pdf: {
              [ApplicationPdf.general]: true,
            },
          },
          widthMode: DynamicFormElementWidthMode.full,
        },
        {
          name: 'travelledCountries',
          type: DynamicFormElementType.text,
          widthMode: DynamicFormElementWidthMode.full,
          visibleIf: ({ hasTravelledOutsideUS }) => hasTravelledOutsideUS,
          meta: {
            pdf: {
              [ApplicationPdf.general]: true,
            },
          },
          props: {
            multiline: true,
            length: 100,
          },
        },
      ],
    },
    {
      name: 'hasFutureTravelPlans',
      elements: [
        {
          name: 'hasFutureTravelPlans',
          noLabel: true,
          type: DynamicFormElementType.booleanRadio,
          widthMode: DynamicFormElementWidthMode.full,
          meta: {
            pdf: {
              [ApplicationPdf.general]: true,
            },
          },
        },
        {
          name: 'futureTravelDestinations',
          type: DynamicFormElementType.text,
          widthMode: DynamicFormElementWidthMode.full,
          visibleIf: ({ hasFutureTravelPlans }) => hasFutureTravelPlans,
          meta: {
            pdf: {
              [ApplicationPdf.general]: true,
            },
          },
          props: {
            multiline: true,
            length: 500,
          },
        },
      ],
    },
    {
      name: 'emergencyContact',
      elements: [
        {
          name: 'emergencyContactFirstName',
          alias: 'firstName',
          type: DynamicFormElementType.text,
          meta: {
            pdf: {
              [ApplicationPdf.agency]: true,
            },
          },
          props: {
            length: 50,
          },
        },
        {
          name: 'emergencyContactLastName',
          alias: 'lastName',
          type: DynamicFormElementType.text,
          meta: {
            pdf: {
              [ApplicationPdf.agency]: true,
            },
          },
          props: {
            length: 50,
          },
        },
        {
          name: 'emergencyContactPhone',
          alias: 'phone',
          type: DynamicFormElementType.phone,
          validIf: value => value.length >= 12,
          meta: {
            pdf: {
              [ApplicationPdf.agency]: true,
            },
          },
          showDescription: true,
        },
      ],
    },
    {
      name: 'relationshipStatus',
      elements: [
        {
          name: 'relationshipStatus',
          noLabel: true,
          type: DynamicFormElementType.enumSelect,
          widthMode: DynamicFormElementWidthMode.full,
          meta: {
            pdf: {
              [ApplicationPdf.general]: true,
              [ApplicationPdf.agency]: true,
            },
          },
          props: {
            enum: RelationshipStatusEnum,
          },
        },
      ],
    },
    {
      name: 'sexualOrientation',
      elements: [
        {
          name: 'sexualOrientation',
          noLabel: true,
          type: DynamicFormElementType.enumSelect,
          widthMode: DynamicFormElementWidthMode.full,
          meta: {
            pdf: {
              [ApplicationPdf.general]: true,
            },
          },
          props: {
            enum: SexualOrientationEnum,
          },
        },
      ],
    },
    {
      name: 'isSexuallyActive',
      elements: [
        {
          name: 'isSexuallyActive',
          noLabel: true,
          type: DynamicFormElementType.booleanRadio,
          widthMode: DynamicFormElementWidthMode.full,
          meta: {
            pdf: {
              [ApplicationPdf.general]: true,
            },
          },
        },
        {
          name: 'isSexuallyActiveWithMultiplePartners',
          showDescription: true,
          visibleIf: ({ isSexuallyActive }) => isSexuallyActive,
          type: DynamicFormElementType.booleanRadio,
          widthMode: DynamicFormElementWidthMode.full,
          meta: {
            pdf: {
              [ApplicationPdf.general]: true,
            },
          },
        },
        {
          name: 'isPartnersAgreeToBloodTest',
          showDescription: true,
          visibleIf: ({ isSexuallyActiveWithMultiplePartners }) => isSexuallyActiveWithMultiplePartners,
          type: DynamicFormElementType.booleanRadio,
          widthMode: DynamicFormElementWidthMode.full,
          meta: {
            pdf: {
              [ApplicationPdf.general]: true,
            },
          },
        },
        {
          name: 'isAbstainFromSexualIntercourse',
          visibleIf: ({ isSexuallyActive }) => isSexuallyActive,
          type: DynamicFormElementType.booleanRadio,
          widthMode: DynamicFormElementWidthMode.full,
          meta: {
            pdf: {
              [ApplicationPdf.general]: true,
            },
          },
        },
      ],
    },
    {
      name: 'otherHouseholdAdults',
      showDescription: true,
      elements: [
        {
          name: 'otherHouseholdAdults',
          noLabel: true,
          type: DynamicFormElementType.text,
          widthMode: DynamicFormElementWidthMode.full,
          meta: {
            pdf: {
              [ApplicationPdf.general]: true,
              [ApplicationPdf.agency]: true,
            },
          },
          props: {
            number: true,
            length: 2,
          },
        },
        {
          name: 'otherHouseholdAdultsRelationship',
          type: DynamicFormElementType.text,
          widthMode: DynamicFormElementWidthMode.full,
          visibleIf: ({ otherHouseholdAdults }) => otherHouseholdAdults > 0,
          meta: {
            pdf: {
              [ApplicationPdf.general]: true,
            },
          },
          props: {
            multiline: true,
            length: 50,
          },
        },
        {
          name: 'isAdultsAgreeToBackgroundCheck',
          type: DynamicFormElementType.booleanRadio,
          widthMode: DynamicFormElementWidthMode.full,
          visibleIf: ({ otherHouseholdAdults }) => otherHouseholdAdults > 0,
          meta: {
            pdf: {
              [ApplicationPdf.general]: true,
            },
          },
        },
      ],
    },
  ],
};
