import { ReactNode } from 'react';
import {
  Checkbox as MuiCheckbox,
  CheckboxProps as MuiCheckboxProps,
  FormControlLabel,
  FormHelperText,
  FormControl,
} from '@mui/material';

export interface CheckboxProps extends MuiCheckboxProps {
  readonly label?: ReactNode;

  readonly error?: boolean;

  readonly helperText?: string;
}

export const Checkbox = ({ label, error, helperText, ...props }: CheckboxProps) => {
  return (
    <FormControl error={error}>
      <FormControlLabel
        label={label}
        labelPlacement="end"
        control={<MuiCheckbox {...props} className={error ? 'Mui-error' : ''} />}
      />
      {helperText && <FormHelperText>{helperText}</FormHelperText>}
    </FormControl>
  );
};
