import { useQuery } from '@apollo/client';
import { useIsMobile } from '@app/app/hooks/useIsMobile';
import { get } from '@app/app/utils/get';
import { getFieldsFromSurveys } from '@app/application/utils/survey';
import { questionnaireConfig } from '@app/questionnaire/configs';
import { questionnaireGetOne } from '@app/questionnaire/gql';
import { Questionnaire } from '@app/questionnaire/types/Questionnaire';
import { Breadcrumbs } from '@app/ui/breadcrumbs';
import { Label } from '@app/ui/label';
import { useModal } from '@app/ui/modal';
import { questionnaireGeneralInfoItem, USER_STATUS_COLOR } from '@app/user/constants';
import { Button, Divider, Grid, InputLabel, Paper, Typography } from '@mui/material';
import { Box } from '@mui/system';
import { Fragment, useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';
import { Loader } from '@app/ui/loader';
import { QuestionnaireList } from './QuestionnaireList';
import { QuestionnaireUploadForm } from '../upload/QuestionnaireUploadForm';
import { QuestionnaireUpdatePdfCoverModal } from '../pdf/QuestionnaireUpdatePdfCoverModal';
import { QuestionnaireExportPdfDropdown } from '../QuestionnaireExportPdfDropdown';

export const AdminQuestionnaireItem = () => {
  const { t } = useTranslation('common');
  const navigate = useNavigate();
  const routerParams = useParams();
  const isMobile = useIsMobile();
  const { isOpen, close, show } = useModal();
  const { entity, ...params } = useParams<{ id?: string; entity: 'surrogates' | 'parent' }>();

  const id = Number(params.id);

  const fields = useMemo(() => {
    const { surveyFields } = getFieldsFromSurveys(questionnaireConfig.surveys);

    return [...surveyFields, 'status', 'user.email'];
  }, []);

  const { data, loading } = useQuery<{ questionnaireGetOne?: Questionnaire }>(questionnaireGetOne(fields), {
    variables: { id },
    fetchPolicy: 'cache-and-network',
  });

  const userName = `${data?.questionnaireGetOne?.firstName || ''} ${data?.questionnaireGetOne?.lastName || ''}`.trim();

  const breadcrumbs = useMemo(
    () => [
      {
        title: t('user.page.parentAdminList.title'),
        to: `/admin/${entity}`,
      },
      {
        title: userName,
      },
    ],
    [t, userName, entity],
  );

  const handleSurveyClick = useCallback(
    (survey: string) => navigate(`/admin/${routerParams.entity}/${id}/${survey}`),
    [id, routerParams],
  );

  return (
    <>
      <Breadcrumbs options={breadcrumbs} />
      <Box mb={3} display="flex" alignItems="center">
        <Typography variant="h1">{userName}</Typography>
        {data?.questionnaireGetOne?.status && (
          <Label
            sx={{ ml: 2, mt: 0.5, textTransform: 'capitalize' }}
            text={t(`questionnaire.field.status.option.${data?.questionnaireGetOne?.status}`)}
            color={USER_STATUS_COLOR[data?.questionnaireGetOne?.status]}
          />
        )}
      </Box>

      <Paper
        sx={{
          wordWrap: 'break-word',
          borderRadius: {
            md: '20px',
          },
          p: {
            xs: 2,
            sm: 3,
            lg: 5,
          },
          mb: 5,
        }}
        variant="outlined"
        elevation={0}
      >
        <Typography variant="h3" sx={{ mb: 3 }}>
          {t('user.page.single.information')}
        </Typography>

        <Grid container direction={isMobile ? 'column' : 'row'}>
          {data?.questionnaireGetOne &&
            questionnaireGeneralInfoItem.map((item, index) => {
              const name = item.split('.').pop();
              const lastChild = index === questionnaireGeneralInfoItem.length - 1;

              return (
                <Fragment key={item}>
                  <Grid item xs md={lastChild ? 3 : 2}>
                    <InputLabel sx={{ mb: 1.5 }}>{t(`questionnaire.field.generalInfo.${name}.label`)}</InputLabel>
                    <div>{get(data.questionnaireGetOne || {}, item) || '-'}</div>
                  </Grid>

                  {!lastChild && (
                    <Divider
                      sx={{
                        my: {
                          xs: 1,
                          sm: 0,
                        },
                        mx: {
                          sm: 2,
                          lg: 3,
                        },
                      }}
                      orientation={isMobile ? 'horizontal' : 'vertical'}
                      flexItem
                    />
                  )}
                </Fragment>
              );
            })}
        </Grid>
      </Paper>

      <Box
        mb={3}
        sx={{
          display: 'flex',
          flexWrap: 'wrap',
          gap: 2,
          alignItems: 'center',
          justifyContent: 'space-between',
        }}
      >
        <Typography variant="h2">{t('user.page.singleParent.subtitle')}</Typography>

        <Box
          sx={{
            display: 'flex',
            gap: {
              xs: 2,
              md: 3,
            },
            flexWrap: 'wrap',
          }}
        >
          <Button className="filledTonal" color="primary" variant="contained" onClick={show}>
            {t('application.button.updateProfilePdf.name')}
          </Button>
          <QuestionnaireExportPdfDropdown questionnaireId={id} label={t('application.button.exportPdf.name')} arrow />
        </Box>
      </Box>

      {isOpen && <QuestionnaireUpdatePdfCoverModal id={id} open={isOpen} onClose={close} />}

      {data?.questionnaireGetOne && (
        <QuestionnaireList item={data.questionnaireGetOne} onClick={handleSurveyClick} isAdmin />
      )}

      <Box mt={4}>
        <Typography variant="h2" mb={3}>
          {t('application.field.photos.label')}
        </Typography>
        <Paper
          sx={{
            p: {
              xs: 2,
              sm: 3,
              lg: 5,
            },
            borderRadius: '20px',
          }}
          variant="outlined"
          elevation={0}
        >
          <Typography variant="h4" mb={3}>
            {t('application.field.photos.subtitle')}
          </Typography>

          {data?.questionnaireGetOne && <QuestionnaireUploadForm questionnaireId={id} />}
        </Paper>
      </Box>

      <Loader isLoading={loading} />
    </>
  );
};
