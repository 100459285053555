import { LightLayout } from '@app/layouts';
import { UserFinishApplication } from '@app/application/components/UserFinishApplication';
import { useNavigate } from 'react-router-dom';

export const SurrogateThankYou = () => {
  const navigate = useNavigate();

  return (
    <LightLayout>
      <UserFinishApplication onBack={() => navigate(-1)} />
    </LightLayout>
  );
};
