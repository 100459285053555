export enum ApplicationStatusEnum {
  lead = 'lead',
  applicants = 'applicants',
  completed = 'completed',
  potential = 'potential',
  available = 'available',
  matched = 'matched',
  delivered = 'delivered',
  comeBackLater = 'comeBackLater',
  purged = 'purged',
  disqualified = 'disqualified',
}

export enum ApplicationSurveyFlagEnum {
  opened = 'opened',
}

export enum ApplicationProgressStatusEnum {
  notStarted = 'notStarted',
  completed = 'completed',
  inProgress = 'inProgress',
}

export enum ApplicationSurveyEnum {
  personalInfo = 'personalInfo',
  relationshipStatus = 'relationshipStatus',
  partnerInfo = 'partnerInfo',
  personalHistory = 'personalHistory',
  employmentQuestions = 'employmentQuestions',
  healthInfo = 'healthInfo',
  aboutYou = 'aboutYou',
  pregnancyHistory = 'pregnancyHistory',
  deliveries = 'deliveries',
  surrogacyJourney = 'surrogacyJourney',
  signatureDisclosure = 'signatureDisclosure',
}

export enum CitizenshipStatusEnum {
  usaCitizen = 'usaCitizen',
  resident = 'resident',
  neither = 'neither',
}

export enum IdentificationTypeEnum {
  driverLicense = 'driverLicense',
  stateIssuedId = 'stateIssuedId',
  neither = 'neither',
}

export enum RelationshipStatusEnum {
  married = 'married',
  livingTogether = 'livingTogether',
  single = 'single',
  dating = 'dating',
  divorced = 'divorced',
  divorcedInRelationship = 'divorcedInRelationship',
  engaged = 'engaged',
  separated = 'separated',
}

export enum SexualOrientationEnum {
  hetero = 'hetero',
  homo = 'homo',
  bi = 'bi',
  other = 'other',
}

export enum InvolvementEnum {
  no = 'no',
  myself = 'myself',
  partner = 'partner',
}

export enum MilitaryStatusEnum {
  no = 'no',
  activeDuty = 'activeDuty',
  militaryReserves = 'militaryReserves',
}

export enum SmokingHabitEnum {
  no = 'no',
  nicotine = 'nicotine',
  cannabis = 'cannabis',
  both = 'both',
}

export enum WillingSmokeOutsideEnum {
  already = 'already',
  yes = 'yes',
  no = 'no',
}

export enum WeeklyEatingOutFrequencyEnum {
  lassThanOnce = 'lassThanOnce',
  once = 'once',
  twoToThree = 'twoToThree',
  fourOrMore = 'fourOrMore',
}

export enum EmploymentTypeEnum {
  partTime = 'partTime',
  fullTime = 'fullTime',
  seasonal = 'seasonal',
}

export enum PayTypeEnum {
  hourly = 'hourly',
  monthly = 'monthly',
  annually = 'annually',
  gig = 'gig',
}

export enum StateDisabilityInsuranceEnum {
  stateFunded = 'stateFunded',
  employerPolicy = 'employerPolicy',
  none = 'none',
  unknown = 'unknown',
}

export enum StateFundedEnum {
  yes = 'yes',
  none = 'none',
  unknown = 'unknown',
}

export enum ProvidedThroughEnum {
  my = 'my',
  partner = 'partner',
  parent = 'parent',
  stateInsurance = 'stateInsurance',
  stateFunded = 'stateFunded',
  other = 'other',
}

export enum ApplicationPdf {
  general = 'general',
  agency = 'agency',
}

export enum BloodTypeEnum {
  aPlus = 'aPlus',
  aMinus = 'aMinus',
  oPlus = 'oPlus',
  oMinus = 'oMinus',
  bPlus = 'bPlus',
  bMinus = 'bMinus',
  abPlus = 'abPlus',
  abMinus = 'abMinus',
  unknown = 'unknown',
}

export enum BirthControlMethodEnum {
  oralBirthControlPill = 'oralBirthControlPill',
  iud = 'iud',
  armImplant = 'armImplant',
  patch = 'patch',
  vaginalRing = 'vaginalRing',
  condoms = 'condoms',
  shotInjection = 'shotInjection',
  tubalLigation = 'tubalLigation',
  partnerVasectomy = 'partnerVasectomy',
  abstinence = 'abstinence',
  none = 'none',
}

export enum ImmunizedHepatitisBEnum {
  yes = 'yes',
  none = 'none',
  unknown = 'unknown',
}

export enum ConsentGetCOVIDVaccineEnum {
  yes = 'yes',
  none = 'none',
  unknown = 'unknown',
}

export enum HaveAlcoholNotPregnantEnum {
  none = 'none',
  rarely = 'rarely',
  oncePerMonth = 'oncePerMonth',
  threePerMonth = 'threePerMonth',
  twoPerWeek = 'twoPerWeek',
  oncePerDay = 'oncePerDay',
}

export enum DiagnosisEnum {
  ADDOrADHD = 'ADDOrADHD',
  anemia = 'anemia',
  anxiety = 'anxiety',
  arthritis = 'arthritis',
  asthma = 'asthma',
  bipolarDisorder = 'bipolarDisorder',
  birthDeformities = 'birthDeformities',
  cancer = 'cancer',
  cerebralPalsy = 'cerebralPalsy',
  chronicBronchitis = 'chronicBronchitis',
  cysticFibrosis = 'cysticFibrosis',
  deafness = 'deafness',
  depression = 'depression',
  diabetes = 'diabetes',
  eatingDisorder = 'eatingDisorder',
  emphysema = 'emphysema',
  endometriosis = 'endometriosis',
  epilepsy = 'epilepsy',
  eyeDisease = 'eyeDisease',
  hpv = 'hpv',
  headInjury = 'headInjury',
  heartProblems = 'heartProblems',
  hemophilia = 'hemophilia',
  hemorrhagingAfterBirth = 'hemorrhagingAfterBirth',
  herpes = 'herpes',
  highCholesterol = 'highCholesterol',
  hypertension = 'hypertension',
  hysterectomy = 'hysterectomy',
  intrauterineFibroids = 'intrauterineFibroids',
  kidneyProblems = 'kidneyProblems',
  learningDisability = 'learningDisability',
  liverDisease = 'liverDisease',
  lungDisease = 'lungDisease',
  mentalIllness = 'mentalIllness',
  mentalRetardation = 'mentalRetardation',
  migraines = 'migraines',
  multipleSclerosis = 'multipleSclerosis',
  muscularDystrophy = 'muscularDystrophy',
  neckBackProblems = 'neckBackProblems',
  nervousSystemProblems = 'nervousSystemProblems',
  obesity = 'obesity',
  paralysis = 'paralysis',
  personalityDisorder = 'personalityDisorder',
  postpartumDepression = 'postpartumDepression',
  preEclampsia = 'preEclampsia',
  preTermLabor = 'preTermLabor',
  schizophrenia = 'schizophrenia',
  seizures = 'seizures',
  sickleCellTrait = 'sickleCellTrait',
  skinDisorders = 'skinDisorders',
  speechProblems = 'speechProblems',
  spinaBifida = 'spinaBifida',
  syphilis = 'syphilis',
  tuberculosis = 'tuberculosis',
  taySachs = 'taySachs',
  thyroidProblems = 'thyroidProblems',
  ulcers = 'ulcers',
}

export enum EmbryoTransferTypeEnum {
  set = 'set',
  det = 'det',
}

export enum NumberOfBabiesEnum {
  singleton = 'singleton',
  twins = 'twins',
  triplets = 'triplets',
  quads = 'quads',
}

export enum IntendedParentContactTypeEnum {
  keepProfessional = 'keepProfessional',
  openToWhatever = 'openToWhatever',
  stayInTouch = 'stayInTouch',
}

export enum IntendedParentTypeEnum {
  alreadyHaveChildren = 'alreadyHaveChildren',
  hetero = 'hetero',
  homo = 'homo',
  singleMale = 'singleMale',
  singleFemale = 'singleFemale',
  singleGayMale = 'singleGayMale',
  singleGayFemale = 'singleGayFemale',
  transgender = 'transgender',
}

export enum ExperienceDuringPregnancyEnum {
  diabetesWithDiet = 'diabetesWithDiet',
  diabetesWithMedication = 'diabetesWithMedication',
  placentaPrevia = 'placentaPrevia',
  preEclampsia = 'preEclampsia',
  cSection = 'cSection',
  none = 'none',
}

export enum DeliveryTypeEnum {
  vaginal = 'vaginal',
  section = 'section',
}

export enum ReferredFromEnum {
  google = 'google',
  youtube = 'youtube',
  pinterest = 'pinterest',
  iscSurrogate = 'iscSurrogate',
  facebook = 'facebook',
  instagram = 'instagram',
  tikTok = 'tikTok',
  specialEvent = 'specialEvent',
  other = 'other',
}

export const heightFtOptions = [4, 5, 6, 7].map(id => ({ id, name: id }));

export const heightInOptions = [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11].map(id => ({ id, name: id }));

export const poundsOptions = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15].map(id => ({ id, name: id }));

export const ouncesOptions = [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16].map(id => ({ id, name: id }));

export const numberOfJobsOptions = [1, 2, 3, 4, 5].map(id => ({ id, name: id }));

export const numberOfMedicalInsuranceOptions = [1, 2, 3].map(id => ({ id, name: id }));

export const numberOfMedicationOptions = [1, 2, 3, 4].map(id => ({ id, name: id }));

export const numberOfDeliveriesOptions = [0, 1, 2, 3, 4, 5, 6, 7].map(id => ({
  id,
  name: `${id}${id === 7 ? '+' : ''}`,
}));

export const numberOfSectionsOptions = [0, 1, 2, 3, 4].map(id => ({ id, name: `${id}${id === 4 ? '+' : ''}` }));

export const numberOfMiscarriagesOptions = [1, 2, 3, 4].map(id => ({ id, name: `${id}${id === 4 ? '+' : ''}` }));

export const numberOfAbortionsOptions = [1, 2, 3, 4].map(id => ({ id, name: `${id}${id === 4 ? '+' : ''}` }));
