import { useMemo, useCallback } from 'react';
import { Form, Formik, FormikHelpers } from 'formik';
import { useNavigate, useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Typography, Button } from '@mui/material';

import { Password, yup } from '@app/formik';
import { useAuthActions } from '@app/auth';
import { PasswordCreateForm } from '@app/auth/types/forms';
import { success } from '@app/snackbars';
import { Loader } from '@app/ui/loader';

const initialValues = {
  password: '',
  confirmPassword: '',
};

export const PasswordCreate = () => {
  const { token = '', entity } = useParams<{ token: string; entity: 'surrogate' | 'parent' }>();
  const isParent = entity === 'parent';

  const navigate = useNavigate();
  const { t } = useTranslation('common');
  const { isLoading, forgotPasswordConfirm, signOut } = useAuthActions();

  const result = useMemo(() => {
    const [code, usernameBase64] = token.split('.');

    try {
      const username = atob(usernameBase64);

      return { code, username };
    } catch (e) {
      console.error(e);
      return { code, username: '' };
    }
  }, [token]);

  const validationSchema = useMemo(
    () =>
      yup.object().shape({
        password: yup
          .string()
          .trim()
          .test('test-pass', t('general.validation.passwordStrong') as string, value =>
            Boolean(
              value &&
                /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[\^$*.[\]{}()?\-“!@#%&/,><’:;|_~`])\S{8,99}$/.test(value),
            ),
          )
          .required(),
        confirmPassword: yup
          .string()
          .test('passwords-match', t('general.validation.passwordMatch') as string, function (value) {
            return this.parent.password === value;
          }),
      }),
    [t],
  );

  const handleSubmit = useCallback(
    async (data: Omit<PasswordCreateForm, 'code'>, helpers: FormikHelpers<Omit<PasswordCreateForm, 'code'>>) => {
      try {
        await forgotPasswordConfirm({ ...data, ...result });
        await signOut();
        navigate(`/sign-in${isParent ? '-parent' : ''}`);
        success(t('auth.page.passwordCreate.successMessage'));
      } catch (e: any) {
        helpers.setErrors({ password: t(`auth.validation.${e.code}`, e.message) as unknown as string });
      }
    },
    [result, t],
  );

  return (
    <>
      <Loader isLoading={isLoading} />

      <Typography variant="h1" gutterBottom>
        {t('auth.page.passwordCreate.title')}
      </Typography>
      <Formik onSubmit={handleSubmit} initialValues={initialValues} validationSchema={validationSchema}>
        {() => (
          <Form noValidate>
            <Password
              required
              margin="normal"
              label={t('user.field.newPassword.label')}
              description={t('general.validation.passwordStrong')}
              name="password"
            />

            <Password label={t('user.field.confirmPassword.label')} required name="confirmPassword" margin="normal" />

            <Button
              sx={{
                width: {
                  xs: '100%',
                  sm: 'auto',
                },
              }}
              type="submit"
              variant="contained"
              color="primary"
            >
              {t('general.button.save')}
            </Button>
          </Form>
        )}
      </Formik>
    </>
  );
};
