import dayJs from 'dayjs';

import {
  DynamicFormArrayType,
  DynamicFormConfigSurvey,
  DynamicFormElementType,
  DynamicFormElementWidthMode,
} from '@app/dynamic';
import { inArray } from '@app/dynamic/utils';

import {
  ApplicationPdf,
  ApplicationSurveyEnum,
  DeliveryTypeEnum,
  NumberOfBabiesEnum,
  poundsOptions,
  ouncesOptions,
  numberOfDeliveriesOptions,
  numberOfSectionsOptions,
} from '../constants';
import cardImage from '../images/card-application-deliveries.jpg';

const showIfSecondBaby = inArray(({ numberOfBabies }) =>
  [NumberOfBabiesEnum.twins, NumberOfBabiesEnum.triplets, NumberOfBabiesEnum.quads].includes(numberOfBabies),
);
const showIfThirdBaby = inArray(({ numberOfBabies }) =>
  [NumberOfBabiesEnum.triplets, NumberOfBabiesEnum.quads].includes(numberOfBabies),
);
const showIfFourthBaby = inArray(({ numberOfBabies }) => NumberOfBabiesEnum.quads === numberOfBabies);

export const deliveries: DynamicFormConfigSurvey<ApplicationSurveyEnum> = {
  name: ApplicationSurveyEnum.deliveries,
  array: {
    type: DynamicFormArrayType.dependent,
    length: ({ numberOfDeliveries }) => numberOfDeliveries || 0,
  },
  meta: {
    json: true,
    cardImage,
    dependOnFields: ['status', 'firstName', 'lastName', 'numberOfSurrogateDeliveries'],
  },
  pages: [
    {
      name: `${ApplicationSurveyEnum.deliveries}.information`,
      information: true,
      elements: [
        {
          name: `${ApplicationSurveyEnum.deliveries}.information`,
          type: DynamicFormElementType.information,
          widthMode: DynamicFormElementWidthMode.full,
          props: {
            variables: {
              time: '5-15',
            },
            template: [['description.item1', 'description.item2', 'description.item3']],
          },
        },
      ],
    },
    {
      name: 'numberOfTotalDeliveries',
      showDescription: true,
      single: {
        index: 0,
      },
      elements: [
        {
          noLabel: true,
          name: 'numberOfDeliveries',
          type: DynamicFormElementType.text,
          widthMode: DynamicFormElementWidthMode.full,
          meta: {
            pdf: {
              [ApplicationPdf.general]: true,
            },
          },
          props: {
            select: true,
            options: numberOfDeliveriesOptions.filter(({ id }) => id !== 0),
          },
        },
        {
          name: 'numberOfSections',
          alias: 'numberOfCSections',
          type: DynamicFormElementType.text,
          widthMode: DynamicFormElementWidthMode.full,
          props: {
            select: true,
            options: numberOfSectionsOptions,
          },
        },
      ],
    },
    {
      name: 'deliveryDetails',
      elements: [
        {
          name: 'deliveries.{index}.firstBaby.firstName',
          alias: 'deliveryDetails.firstName',
          type: DynamicFormElementType.text,
          widthMode: DynamicFormElementWidthMode.full,
          meta: {
            pdf: {
              [ApplicationPdf.general]: true,
            },
          },
          props: {
            length: 30,
          },
        },
        {
          name: 'deliveries.{index}.firstBaby.dateOfBirth',
          alias: 'deliveryDetails.dateOfBirth',
          type: DynamicFormElementType.date,
          widthMode: DynamicFormElementWidthMode.half,
          meta: {
            pdf: {
              [ApplicationPdf.general]: true,
            },
          },
          props: {
            openTo: 'year',
            minDate: dayJs().subtract(40, 'years'),
            maxDate: dayJs(),
            views: ['year', 'month', 'day'],
          },
        },
        {
          name: 'deliveries.{index}.firstBaby.birthWeightPounds',
          alias: 'deliveryDetails.birthWeightPounds',
          type: DynamicFormElementType.text,
          meta: {
            pdf: {
              [ApplicationPdf.general]: true,
            },
          },
          props: {
            select: true,
            options: poundsOptions,
          },
        },
        {
          name: 'deliveries.{index}.firstBaby.birthWeightOunces',
          alias: 'deliveryDetails.birthWeightOunces',
          type: DynamicFormElementType.text,
          meta: {
            pdf: {
              [ApplicationPdf.general]: true,
            },
          },
          props: {
            select: true,
            options: ouncesOptions,
          },
        },
      ],
    },
    {
      name: 'deliveryDetailsQuantity',
      alias: 'deliveryDetails',
      elements: [
        {
          name: 'deliveries.{index}.firstBaby.deliveryType',
          alias: 'deliveryDetails.deliveryType',
          widthMode: DynamicFormElementWidthMode.full,
          type: DynamicFormElementType.enumRadio,
          meta: {
            pdf: {
              [ApplicationPdf.general]: true,
            },
          },
          props: {
            enum: DeliveryTypeEnum,
          },
        },
        {
          name: 'deliveries.{index}.firstBaby.gestationWeeks',
          alias: 'deliveryDetails.gestationWeeks',
          widthMode: DynamicFormElementWidthMode.full,
          type: DynamicFormElementType.text,
          meta: {
            pdf: {
              [ApplicationPdf.general]: true,
            },
          },
          props: {
            length: 50,
            multiline: true,
          },
        },
        {
          name: 'deliveries.{index}.numberOfBabies',
          alias: 'deliveryDetails.numberOfBabies',
          widthMode: DynamicFormElementWidthMode.full,
          type: DynamicFormElementType.enumSelect,
          meta: {
            pdf: {
              [ApplicationPdf.general]: true,
            },
          },
          props: {
            enum: NumberOfBabiesEnum,
          },
        },
      ],
    },
    {
      name: 'deliveryDetailsSecondBaby',
      alias: 'deliveryDetails',
      visibleIf: showIfSecondBaby,
      elements: [
        {
          name: 'deliveries.{index}.secondBaby.firstName',
          alias: 'deliveryDetails.secondBaby.firstName',
          type: DynamicFormElementType.text,
          widthMode: DynamicFormElementWidthMode.full,
          meta: {
            pdf: {
              [ApplicationPdf.general]: true,
            },
          },
          props: {
            length: 30,
          },
        },
        {
          name: 'deliveries.{index}.secondBaby.dateOfBirth',
          alias: 'deliveryDetails.dateOfBirth',
          type: DynamicFormElementType.date,
          widthMode: DynamicFormElementWidthMode.half,
          meta: {
            pdf: {
              [ApplicationPdf.general]: true,
            },
          },
          props: {
            openTo: 'year',
            minDate: dayJs().subtract(40, 'years'),
            maxDate: dayJs(),
            views: ['year', 'month', 'day'],
          },
        },
        {
          name: 'deliveries.{index}.secondBaby.birthWeightPounds',
          alias: 'deliveryDetails.birthWeightPounds',
          type: DynamicFormElementType.text,
          meta: {
            pdf: {
              [ApplicationPdf.general]: true,
            },
          },
          props: {
            select: true,
            options: poundsOptions,
          },
        },
        {
          name: 'deliveries.{index}.secondBaby.birthWeightOunces',
          alias: 'deliveryDetails.birthWeightOunces',
          type: DynamicFormElementType.text,
          meta: {
            pdf: {
              [ApplicationPdf.general]: true,
            },
          },
          props: {
            select: true,
            options: ouncesOptions,
          },
        },
      ],
    },
    {
      name: 'deliveryDetailsSecondBaby2',
      alias: 'deliveryDetails',
      visibleIf: showIfSecondBaby,
      elements: [
        {
          name: 'deliveries.{index}.secondBaby.deliveryType',
          alias: 'deliveryDetails.deliveryType',
          widthMode: DynamicFormElementWidthMode.full,
          type: DynamicFormElementType.enumRadio,
          meta: {
            pdf: {
              [ApplicationPdf.general]: true,
            },
          },
          props: {
            enum: DeliveryTypeEnum,
          },
        },
        {
          name: 'deliveries.{index}.secondBaby.gestationWeeks',
          alias: 'deliveryDetails.secondBaby.gestationWeeks',
          widthMode: DynamicFormElementWidthMode.full,
          type: DynamicFormElementType.text,
          meta: {
            pdf: {
              [ApplicationPdf.general]: true,
            },
          },
          props: {
            length: 50,
            multiline: true,
          },
        },
      ],
    },
    {
      name: 'deliveryDetailsThirdBaby',
      alias: 'deliveryDetails',
      visibleIf: showIfThirdBaby,
      elements: [
        {
          name: 'deliveries.{index}.thirdBaby.firstName',
          alias: 'deliveryDetails.thirdBaby.firstName',
          type: DynamicFormElementType.text,
          widthMode: DynamicFormElementWidthMode.full,
          meta: {
            pdf: {
              [ApplicationPdf.general]: true,
            },
          },
          props: {
            length: 30,
          },
        },
        {
          name: 'deliveries.{index}.thirdBaby.dateOfBirth',
          alias: 'deliveryDetails.dateOfBirth',
          type: DynamicFormElementType.date,
          widthMode: DynamicFormElementWidthMode.half,
          meta: {
            pdf: {
              [ApplicationPdf.general]: true,
            },
          },
          props: {
            openTo: 'year',
            minDate: dayJs().subtract(40, 'years'),
            maxDate: dayJs(),
            views: ['year', 'month', 'day'],
          },
        },
        {
          name: 'deliveries.{index}.thirdBaby.birthWeightPounds',
          alias: 'deliveryDetails.birthWeightPounds',
          type: DynamicFormElementType.text,
          meta: {
            pdf: {
              [ApplicationPdf.general]: true,
            },
          },
          props: {
            select: true,
            options: poundsOptions,
          },
        },
        {
          name: 'deliveries.{index}.thirdBaby.birthWeightOunces',
          alias: 'deliveryDetails.birthWeightOunces',
          type: DynamicFormElementType.text,
          meta: {
            pdf: {
              [ApplicationPdf.general]: true,
            },
          },
          props: {
            select: true,
            options: ouncesOptions,
          },
        },
      ],
    },
    {
      name: 'deliveryDetailsThirdBaby2',
      alias: 'deliveryDetails',
      visibleIf: showIfThirdBaby,
      elements: [
        {
          name: 'deliveries.{index}.thirdBaby.deliveryType',
          alias: 'deliveryDetails.deliveryType',
          widthMode: DynamicFormElementWidthMode.full,
          type: DynamicFormElementType.enumRadio,
          meta: {
            pdf: {
              [ApplicationPdf.general]: true,
            },
          },
          props: {
            enum: DeliveryTypeEnum,
          },
        },
        {
          name: 'deliveries.{index}.thirdBaby.gestationWeeks',
          alias: 'deliveryDetails.thirdBaby.gestationWeeks',
          widthMode: DynamicFormElementWidthMode.full,
          type: DynamicFormElementType.text,
          meta: {
            pdf: {
              [ApplicationPdf.general]: true,
            },
          },
          props: {
            length: 50,
            multiline: true,
          },
        },
      ],
    },
    {
      name: 'deliveryDetailsFourthBaby',
      alias: 'deliveryDetails',
      visibleIf: showIfFourthBaby,
      elements: [
        {
          name: 'deliveries.{index}.fourthBaby.firstName',
          alias: 'deliveryDetails.fourthBaby.firstName',
          type: DynamicFormElementType.text,
          widthMode: DynamicFormElementWidthMode.full,
          meta: {
            pdf: {
              [ApplicationPdf.general]: true,
            },
          },
          props: {
            length: 30,
          },
        },
        {
          name: 'deliveries.{index}.fourthBaby.dateOfBirth',
          alias: 'deliveryDetails.dateOfBirth',
          type: DynamicFormElementType.date,
          widthMode: DynamicFormElementWidthMode.half,
          meta: {
            pdf: {
              [ApplicationPdf.general]: true,
            },
          },
          props: {
            openTo: 'year',
            minDate: dayJs().subtract(40, 'years'),
            maxDate: dayJs(),
            views: ['year', 'month', 'day'],
          },
        },
        {
          name: 'deliveries.{index}.fourthBaby.birthWeightPounds',
          alias: 'deliveryDetails.birthWeightPounds',
          type: DynamicFormElementType.text,
          meta: {
            pdf: {
              [ApplicationPdf.general]: true,
            },
          },
          props: {
            select: true,
            options: poundsOptions,
          },
        },
        {
          name: 'deliveries.{index}.fourthBaby.birthWeightOunces',
          alias: 'deliveryDetails.birthWeightOunces',
          type: DynamicFormElementType.text,
          meta: {
            pdf: {
              [ApplicationPdf.general]: true,
            },
          },
          props: {
            select: true,
            options: ouncesOptions,
          },
        },
      ],
    },
    {
      name: 'deliveryDetailsFourthBaby2',
      alias: 'deliveryDetails',
      visibleIf: showIfFourthBaby,
      elements: [
        {
          name: 'deliveries.{index}.fourthBaby.deliveryType',
          alias: 'deliveryDetails.deliveryType',
          widthMode: DynamicFormElementWidthMode.full,
          type: DynamicFormElementType.enumRadio,
          meta: {
            pdf: {
              [ApplicationPdf.general]: true,
            },
          },
          props: {
            enum: DeliveryTypeEnum,
          },
        },
        {
          name: 'deliveries.{index}.fourthBaby.gestationWeeks',
          alias: 'deliveryDetails.fourthBaby.gestationWeeks',
          widthMode: DynamicFormElementWidthMode.full,
          type: DynamicFormElementType.text,
          meta: {
            pdf: {
              [ApplicationPdf.general]: true,
            },
          },
          props: {
            length: 50,
            multiline: true,
          },
        },
      ],
    },
    {
      name: 'deliveryDetailsComplications',
      alias: 'deliveryDetails',
      elements: [
        {
          name: 'deliveries.{index}.complications',
          alias: 'deliveryDetails.complications',
          widthMode: DynamicFormElementWidthMode.full,
          type: DynamicFormElementType.booleanRadio,
          meta: {
            pdf: {
              [ApplicationPdf.general]: true,
            },
          },
        },
        {
          name: 'deliveries.{index}.complicationsDetails',
          alias: 'deliveryDetails.complicationsDetails',
          widthMode: DynamicFormElementWidthMode.full,
          visibleIf: inArray(({ complications }) => complications),
          type: DynamicFormElementType.text,
          meta: {
            pdf: {
              [ApplicationPdf.general]: true,
            },
          },
          props: {
            multiline: true,
            length: 1000,
          },
        },
        {
          name: 'deliveries.{index}.useMedications',
          alias: 'deliveryDetails.useMedications',
          widthMode: DynamicFormElementWidthMode.full,
          type: DynamicFormElementType.booleanRadio,
          meta: {
            pdf: {
              [ApplicationPdf.general]: true,
            },
          },
        },
        {
          name: 'deliveries.{index}.useMedicationsList',
          alias: 'deliveryDetails.useMedicationsList',
          visibleIf: inArray(({ useMedications }) => useMedications),
          widthMode: DynamicFormElementWidthMode.full,
          type: DynamicFormElementType.text,
          meta: {
            pdf: {
              [ApplicationPdf.general]: true,
            },
          },
          props: {
            multiline: true,
            length: 1000,
          },
        },
      ],
    },
    {
      name: 'deliveryDetailsSurrogacyPregnancy',
      alias: 'deliveryDetails',
      visibleIf: inArray(({ index }) => index !== 0),
      elements: [
        {
          name: 'deliveries.{index}.isSurrogacyPregnancy',
          alias: 'deliveryDetails.isSurrogacyPregnancy',
          widthMode: DynamicFormElementWidthMode.full,
          type: DynamicFormElementType.booleanRadio,
          meta: {
            pdf: {
              [ApplicationPdf.general]: true,
            },
          },
        },
        {
          name: 'deliveries.{index}.numberOfEmbryos',
          alias: 'deliveryDetails.numberOfEmbryos',
          visibleIf: inArray(({ isSurrogacyPregnancy }) => isSurrogacyPregnancy),
          type: DynamicFormElementType.text,
          meta: {
            pdf: {
              [ApplicationPdf.general]: true,
            },
          },
          props: {
            number: true,
            length: 1,
          },
        },
        {
          name: 'deliveries.{index}.numberOfHeartbeats',
          alias: 'deliveryDetails.numberOfHeartbeats',
          visibleIf: inArray(({ isSurrogacyPregnancy }) => isSurrogacyPregnancy),
          type: DynamicFormElementType.text,
          meta: {
            pdf: {
              [ApplicationPdf.general]: true,
            },
          },
          props: {
            number: true,
            length: 1,
          },
        },
      ],
    },
    {
      name: 'deliveryDetailsCycleCancelled',
      alias: 'deliveryDetails',
      visibleIf: inArray(({ isSurrogacyPregnancy }) => isSurrogacyPregnancy),
      elements: [
        {
          name: 'deliveries.{index}.isSurrogacySuccessful',
          alias: 'deliveryDetails.isSurrogacySuccessful',
          widthMode: DynamicFormElementWidthMode.full,
          type: DynamicFormElementType.booleanRadio,
          visibleIf: inArray(({ index }) => index !== 0),
          meta: {
            pdf: {
              [ApplicationPdf.general]: true,
            },
          },
        },
        {
          name: 'deliveries.{index}.isCycleCancelled',
          alias: 'deliveryDetails.isCycleCancelled',
          widthMode: DynamicFormElementWidthMode.full,
          type: DynamicFormElementType.booleanRadio,
          meta: {
            pdf: {
              [ApplicationPdf.general]: true,
            },
          },
        },
        {
          name: 'deliveries.{index}.isCycleCancelledDetails',
          alias: 'pleaseExplain',
          visibleIf: inArray(({ isCycleCancelled }) => isCycleCancelled),
          widthMode: DynamicFormElementWidthMode.full,
          type: DynamicFormElementType.text,
          meta: {
            pdf: {
              [ApplicationPdf.general]: true,
            },
          },
          props: {
            multiline: true,
            length: 1000,
          },
        },
        {
          name: 'deliveries.{index}.numberOfTransfersBefore',
          alias: 'deliveryDetails.numberOfTransfersBefore',
          widthMode: DynamicFormElementWidthMode.full,
          type: DynamicFormElementType.text,
          meta: {
            pdf: {
              [ApplicationPdf.general]: true,
            },
          },
          props: {
            number: true,
            length: 2,
          },
        },
      ],
    },
  ],
};
