import { DynamicFormConfigSurvey, DynamicFormElementType, DynamicFormElementWidthMode } from '@app/dynamic';

import { ApplicationPdf, ApplicationSurveyEnum } from '../constants';
import cardImage from '../images/card-application-signature-disclosure.jpg';

export const signatureDisclosure: DynamicFormConfigSurvey<ApplicationSurveyEnum> = {
  name: ApplicationSurveyEnum.signatureDisclosure,
  meta: {
    cardImage,
    dependOnFields: ['status', 'firstName', 'lastName'],
  },
  pages: [
    {
      name: `${ApplicationSurveyEnum.signatureDisclosure}.information`,
      information: true,
      elements: [
        {
          name: `${ApplicationSurveyEnum.signatureDisclosure}.information`,
          type: DynamicFormElementType.information,
          widthMode: DynamicFormElementWidthMode.full,
          props: {
            variables: {
              time: '5',
            },
            template: [['description.item1', 'description.item2']],
          },
        },
      ],
    },
    {
      name: 'medicalRecordsDisclosure',
      elements: [
        {
          name: 'medicalRecordsDisclosure.information',
          type: DynamicFormElementType.information,
          widthMode: DynamicFormElementWidthMode.full,
          props: {
            pageInner: true,
            template: ['text'],
          },
        },
        {
          name: 'medicalRecordsDisclosure',
          alias: 'medicalRecordsDisclosure.agreementText',
          type: DynamicFormElementType.checkbox,
          widthMode: DynamicFormElementWidthMode.full,
          validIf: value => Boolean(value),
          meta: {
            pdf: {
              [ApplicationPdf.agency]: true,
            },
          },
        },
      ],
    },
    {
      name: 'criminalCheckDisclosure',
      elements: [
        {
          name: 'criminalCheckDisclosure.information',
          type: DynamicFormElementType.information,
          widthMode: DynamicFormElementWidthMode.full,
          props: {
            pageInner: true,
            template: ['text'],
          },
        },
        {
          name: 'criminalCheckDisclosure',
          alias: 'criminalCheckDisclosure.agreementText',
          type: DynamicFormElementType.checkbox,
          widthMode: DynamicFormElementWidthMode.full,
          validIf: value => Boolean(value),
          meta: {
            pdf: {
              [ApplicationPdf.agency]: true,
            },
          },
        },
      ],
    },
    {
      name: 'photoUsageDisclosure',
      elements: [
        {
          name: 'photoUsageDisclosure',
          alias: 'photoUsageDisclosure.agreementText',
          type: DynamicFormElementType.checkbox,
          widthMode: DynamicFormElementWidthMode.full,
          validIf: value => Boolean(value),
          meta: {
            pdf: {
              [ApplicationPdf.agency]: true,
            },
          },
        },
      ],
    },
    {
      name: 'acceptInformationApp',
      elements: [
        {
          name: 'acceptInformationApp',
          alias: 'acceptInformationApp.agreementText',
          type: DynamicFormElementType.checkbox,
          widthMode: DynamicFormElementWidthMode.full,
          validIf: value => Boolean(value),
          meta: {
            pdf: {
              [ApplicationPdf.agency]: true,
            },
          },
        },
      ],
    },
  ],
};
