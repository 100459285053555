import dayJs from 'dayjs';
import { useMemo } from 'react';
import { useTranslation, Trans } from 'react-i18next';
import { StyleSheet, Text, View } from '@react-pdf/renderer';

import { appConfig } from '@app/app';
import { palette } from '@app/app/configs/theme';
import { DynamicFormConfigElement, DynamicFormElementType } from '@app/dynamic';
import { Application } from '@app/application/types/Application';
import { Questionnaire } from '@app/questionnaire/types/Questionnaire';

import { mainStyles } from './styles';

export interface ApplicationPdfDocumentElementProps {
  readonly config: DynamicFormConfigElement;
  readonly value: string;
  readonly data: Partial<Application | Questionnaire>;
}

export const ApplicationPdfDocumentElement = ({ config, value, data }: ApplicationPdfDocumentElementProps) => {
  const { t } = useTranslation('common');

  const alias = config?.alias ?? config.name;
  const translation = config?.translation ?? 'application.field';

  const transformedValue = useMemo(() => {
    switch (config.type) {
      case DynamicFormElementType.autocomplete:
      case DynamicFormElementType.text: {
        if (config.type === DynamicFormElementType.autocomplete || config.props?.select) {
          const found = config.props?.options?.find(option => option.id === value);

          if (found) {
            return found.name;
          }
        }

        return value;
      }
      case DynamicFormElementType.enumRadio:
      case DynamicFormElementType.enumCheckbox:
      case DynamicFormElementType.enumSelect: {
        // @ts-ignore
        const tName = config?.props?.translation ?? `${translation}.${config?.aliasOption ?? alias}.option`;

        return Array.isArray(value)
          ? value.map(subValue => t(`${tName}.${subValue}`)).join('\n')
          : t(`${tName}.${value}`);
      }
      case DynamicFormElementType.checkbox:
      case DynamicFormElementType.toggleButton:
      case DynamicFormElementType.booleanRadio: {
        const trans = config?.props?.translation ?? `${translation}.${config?.aliasOption ?? alias}.option`;

        if (value) {
          return t(`${trans}.yes`, t('general.option.yes'));
        }

        return t(`${trans}.no`, t('general.option.no'));
      }
      case DynamicFormElementType.date: {
        return dayJs(value, appConfig.format.isoDateTime).format(appConfig.format.date);
      }
      default:
        return value;
    }
  }, [value, config]);

  return (
    <View style={styles.surveyItem}>
      <Text style={styles.surveyLabel}>
        <Trans
          i18nKey={t(
            `${translation}.${config.name}.pdf.label`,
            t(`${translation}.${alias}.label`, t(`${translation}.${alias}.subtitle`)),
            { ...data },
          )}
          components={{
            u: <Text style={styles.underline} />,
          }}
        />
      </Text>
      <Text style={[styles.surveyValue, mainStyles.text]}>{transformedValue}</Text>
    </View>
  );
};

const styles = StyleSheet.create({
  surveyItem: {
    borderBottom: `0.75pt solid ${palette.grey[100]}`,
    paddingHorizontal: 6,
    paddingVertical: 8,
    display: 'flex',
    flexDirection: 'row',
  },
  surveyLabel: {
    color: palette.text.secondary,
    fontWeight: 600,
    width: '45%',
    minWidth: '45%',
    marginRight: 25,
  },
  surveyValue: {
    flex: 1,
  },
  underline: {
    textDecoration: 'underline',
  },
});
