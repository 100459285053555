import { Area } from 'react-easy-crop';
import { mimeTypeConfig } from '../configs/mimeType';

export const getMimeType = (filename?: string) => {
  const ext = (filename || '').toLowerCase().split('.').pop() as 'jpg';

  return mimeTypeConfig[ext || 'jpg'] || mimeTypeConfig.jpg;
};

export const getCroppedImg = (file: File, croppedAreaPixels: Area): Promise<File> => {
  return new Promise((resolve, reject) => {
    const canvas = document.createElement('canvas');
    const ctx = canvas.getContext('2d');

    if (!ctx) {
      reject(new Error('Canvas context is not available'));
      return;
    }

    const img = new Image();
    img.src = URL.createObjectURL(file);

    img.onload = () => {
      canvas.width = croppedAreaPixels.width;
      canvas.height = croppedAreaPixels.height;

      ctx.drawImage(
        img,
        croppedAreaPixels.x,
        croppedAreaPixels.y,
        croppedAreaPixels.width,
        croppedAreaPixels.height,
        0,
        0,
        croppedAreaPixels.width,
        croppedAreaPixels.height,
      );

      canvas.toBlob(blob => {
        if (blob) {
          resolve(new File([blob], file.name, { type: file.type }));
        } else {
          reject(new Error('Canvas is empty'));
        }
      }, file.type);
    };
  });
};
