import { useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { Form, Formik, FormikHelpers } from 'formik';
import { error } from '@app/snackbars';
import { useGoogleReCaptcha } from 'react-google-recaptcha-v3';
import { Card } from '@app/ui/card';
import { Loader } from '@app/ui/loader';
import { Box, Button, Grid, Typography } from '@mui/material';
import {
  Autocomplete,
  BooleanRadio,
  EnumRadioGroup,
  EnumSelect,
  Password,
  Phone,
  ScrollToError,
  Text,
  yup,
} from '@app/formik';
import { COUNTRIES } from '@app/application/constants/countries';
import { ApolloError, useMutation } from '@apollo/client';
import { extractValidationErrors } from '@app/query';
import { useNavigate } from 'react-router-dom';
import {
  PreferredContactMethodEnum,
  EmbryosStatusEnum,
  JourneyReadinessEnum,
  HowDidYouHearEnum,
} from '@app/questionnaire/constants';
import { useAuthActions } from '../hooks/useAuthActions';

import imgCard from '../images/img-card-sign-up-parent.png';
import { SignUpParentForm } from '../types/forms';
import { TermsAndMessageConsentCheckboxes } from './TermsAndConsentCheckboxes';
import { AUTH_SIGN_UP_PARENT } from '../gql';
import { isPasswordStrong } from '../utils';

const initialValues: Partial<SignUpParentForm> =
  process.env.NODE_ENV === 'development'
    ? {
        firstName: 'John',
        lastName: 'Confirm 2',
        country: 'US',
        phone: '+12312312312',
        email: 'mww+confirm2@mindk.com',
        password: '',
        isPartnerInformation: true,
        partnerFirstName: 'Anna',
        partnerLastName: 'Name',
        partnerPhone: '+12312312312',
        partnerEmail: 'anna@mail.com',
        preferredContactMethod: PreferredContactMethodEnum.other,
        specificContactMethod: 'Telegram',
        embryosStatus: EmbryosStatusEnum.inProgress,
        isWorkingWithIVF: true,
        clinicName: 'Clinic',
        journeyReadiness: JourneyReadinessEnum.newTo,
        referredFrom: HowDidYouHearEnum.other,
        referredDescription: 'Some',
        terms: true,
        messageConsent: false,
      }
    : {
        firstName: '',
        lastName: '',
        country: '',
        phone: '',
        email: '',
        password: '',
        isPartnerInformation: false,
        partnerFirstName: '',
        partnerLastName: '',
        partnerPhone: '',
        partnerEmail: '',
        preferredContactMethod: undefined,
        specificContactMethod: '',
        embryosStatus: undefined,
        isWorkingWithIVF: false,
        clinicName: '',
        journeyReadiness: undefined,
        referredFrom: undefined,
        referredDescription: '',
        terms: false,
        messageConsent: false,
      };

export const SignUpParent = () => {
  const { t } = useTranslation('common');
  const navigate = useNavigate();

  const { executeRecaptcha } = useGoogleReCaptcha();
  const { isLoading, signIn, signOut } = useAuthActions();

  const [signUpParent, { loading }] = useMutation<{ authSignUpParent: { id: number } }>(AUTH_SIGN_UP_PARENT, {
    fetchPolicy: 'network-only',
  });

  const validationSchema = useMemo(
    () =>
      yup.object().shape({
        firstName: yup.string().required().max(50),
        lastName: yup.string().required().max(50),
        country: yup.string().required(),
        phone: yup
          .string()
          .phone('USA', false, t('general.validation.phoneNumber') as string)
          .typeError(t('general.validation.phoneNumber') as string),
        email: yup.string().required().email(),
        password: yup
          .string()
          .trim()
          .test('test-pass', t('general.validation.passwordStrong') as string, isPasswordStrong)
          .required(),
        isPartnerInformation: yup.boolean().required(),
        partnerFirstName: yup.string().max(50).when('isPartnerInformation', {
          is: true,
          then: yup.string().required(),
        }),
        partnerLastName: yup.string().max(50),
        partnerPhone: yup.string().when('isPartnerInformation', {
          is: true,
          then: yup
            .string()
            .phone('USA', false, t('general.validation.partnerPhoneNumber') as string)
            .typeError(t('general.validation.partnerPhoneNumber') as string),
        }),
        partnerEmail: yup.string().when('isPartnerInformation', {
          is: true,
          then: yup.string().email(),
        }),
        preferredContactMethod: yup.string(),
        specificContactMethod: yup.string().max(50).when('preferredContactMethod', {
          is: PreferredContactMethodEnum.other,
          then: yup.string().required(),
        }),
        embryosStatus: yup.string().required(),
        isWorkingWithIVF: yup.boolean().required(),
        clinicName: yup.string().max(20).when('isWorkingWithIVF', {
          is: true,
          then: yup.string().required(),
        }),
        journeyReadiness: yup.string().required(),
        referredFrom: yup.string().required(),
        referredDescription: yup
          .string()
          .max(100)
          .when('referredFrom', {
            is: (value: HowDidYouHearEnum | string) =>
              value === HowDidYouHearEnum.referred ||
              value === HowDidYouHearEnum.socialMedia ||
              value === HowDidYouHearEnum.specialEvent ||
              value === HowDidYouHearEnum.other,
            then: yup.string().max(100).required(),
          }),
        terms: yup
          .boolean()
          .oneOf([true], String(t('general.validation.required')))
          .required(),
        messageConsent: yup.boolean(),
      }),
    [t],
  );

  const handleSubmit = useCallback(
    async (
      { specificContactMethod, ...input }: Partial<SignUpParentForm>,
      helpers: FormikHelpers<Partial<SignUpParentForm>>,
    ) => {
      if (!executeRecaptcha) {
        error(t('general.error.somethingWentWrong'));
      } else {
        try {
          const recaptchaToken = await executeRecaptcha('signUpParent');

          const data = await signUpParent({
            variables: {
              input: {
                ...input,
                specificContactMethod: undefined,
                preferredContactMethod:
                  input.preferredContactMethod === PreferredContactMethodEnum.other
                    ? specificContactMethod
                    : input?.preferredContactMethod || '',
                recaptchaToken,
              },
            },
          });

          if (data.data?.authSignUpParent.id) {
            try {
              await signOut();
              await signIn({ email: input.email as string, password: input.password as string });
              navigate('/thank-you-parent');
            } catch (e) {
              console.error(e);
            }
          }
        } catch (e) {
          const errors = extractValidationErrors(e as ApolloError);

          if (Object.keys(errors).length > 0) {
            helpers.setErrors(errors);
          } else {
            error((e as ApolloError).message || t('general.error.somethingWentWrong'));
          }
        }
      }
    },
    [t, executeRecaptcha],
  );

  return (
    <>
      <Loader isLoading={isLoading || loading} />

      <Card size="large" img={imgCard}>
        <Formik onSubmit={handleSubmit} initialValues={initialValues} validationSchema={validationSchema}>
          {({ values }) => {
            return useMemo(
              () => (
                <Form noValidate autoComplete="off">
                  <Typography variant="h1" gutterBottom>
                    {t('auth.page.signUpParent.title')}
                  </Typography>

                  <Box mb={4}>
                    <Grid container spacing={{ xs: 2, sm: 3 }}>
                      <Grid item xs={12} sm={6}>
                        <Text required label={t('application.field.firstName.label')} name="firstName" />
                      </Grid>

                      <Grid item xs={12} sm={6}>
                        <Text required label={t('application.field.lastName.label')} name="lastName" />
                      </Grid>

                      <Grid item xs={12} sm={6}>
                        <Autocomplete
                          required
                          label={t('auth.field.country.label')}
                          name="country"
                          options={COUNTRIES}
                        />
                      </Grid>

                      <Grid item xs={12} sm={6}>
                        <Phone
                          required
                          disableDropdown={false}
                          forceCallingCode={false}
                          name="phone"
                          label={t('application.field.phone.label')}
                          description={t('application.field.phone.description')}
                        />
                      </Grid>

                      <Grid item xs={12} sm={6}>
                        <Text required label={t('user.field.email.label')} name="email" type="email" />
                      </Grid>

                      <Grid item xs={12} sm={6}>
                        <Password
                          required
                          label={t('auth.field.password.label')}
                          description={t('general.validation.passwordStrong')}
                          name="password"
                        />
                      </Grid>

                      <Grid item xs={12}>
                        <BooleanRadio
                          label={t('auth.field.isPartnerInformation.label')}
                          required
                          name="isPartnerInformation"
                        />
                      </Grid>

                      {values.isPartnerInformation && (
                        <>
                          <Grid item xs={12} sm={6}>
                            <Text label={t('auth.field.partnerFirstName.label')} name="partnerFirstName" />
                          </Grid>

                          <Grid item xs={12} sm={6}>
                            <Text label={t('auth.field.partnerLastName.label')} name="partnerLastName" />
                          </Grid>

                          <Grid item xs={12} sm={6}>
                            <Phone
                              required
                              disableDropdown={false}
                              forceCallingCode={false}
                              name="partnerPhone"
                              label={t('auth.field.partnerPhone.label')}
                            />
                          </Grid>

                          <Grid item xs={12} sm={6}>
                            <Text label={t('auth.field.partnerEmail.label')} name="partnerEmail" type="email" />
                          </Grid>
                        </>
                      )}

                      <Grid item xs={12} sm={6}>
                        <EnumSelect
                          label={t('auth.field.preferredContactMethod.label')}
                          name="preferredContactMethod"
                          enum={PreferredContactMethodEnum}
                          translation="auth.field.preferredContactMethod.option"
                        />
                      </Grid>

                      {values.preferredContactMethod === PreferredContactMethodEnum.other && (
                        <Grid item xs={12} sm={6}>
                          <Text label={t('auth.field.specificContactMethod.label')} name="specificContactMethod" />
                        </Grid>
                      )}

                      <Grid item xs={12}>
                        <EnumSelect
                          label={t('auth.field.embryosStatus.label')}
                          name="embryosStatus"
                          enum={EmbryosStatusEnum}
                          translation="auth.field.embryosStatus.option"
                        />
                      </Grid>

                      <Grid item xs={12}>
                        <BooleanRadio label={t('auth.field.isWorkingWithIVF.label')} required name="isWorkingWithIVF" />
                      </Grid>

                      {values.isWorkingWithIVF && (
                        <Grid item xs={12} sm={6}>
                          <Text label={t('auth.field.clinicName.label')} name="clinicName" />
                        </Grid>
                      )}

                      <Grid item xs={12}>
                        <EnumRadioGroup
                          required
                          name="journeyReadiness"
                          enum={JourneyReadinessEnum}
                          translation="auth.field.journeyReadiness.option"
                          label={t('auth.field.journeyReadiness.label')}
                        />
                      </Grid>

                      <Grid item xs={12} sm={6}>
                        <EnumSelect
                          required
                          enum={HowDidYouHearEnum}
                          translation="auth.field.referredFrom.option"
                          label={t('auth.field.referredFrom.label')}
                          name="referredFrom"
                        />
                      </Grid>

                      {[
                        HowDidYouHearEnum.referred,
                        HowDidYouHearEnum.socialMedia,
                        HowDidYouHearEnum.specialEvent,
                        HowDidYouHearEnum.other,
                      ].includes(values.referredFrom as HowDidYouHearEnum) && (
                        <Grid item xs={12}>
                          <Text label={t('auth.field.referredDescription.label')} name="referredDescription" />
                        </Grid>
                      )}
                    </Grid>
                  </Box>

                  <Box>
                    <Grid container spacing={{ xs: 2, sm: 3 }}>
                      <Grid item xs={12}>
                        <TermsAndMessageConsentCheckboxes
                          termsLabel={t('auth.page.signUp.signUpConfirm')}
                          messageConsentLabel={t('auth.page.signUp.messageConsent')}
                        />
                      </Grid>
                    </Grid>
                  </Box>

                  <Box display="flex" justifyContent="flex-end" mt={3}>
                    <Button
                      sx={{
                        width: {
                          xs: '100%',
                          sm: 'auto',
                        },
                      }}
                      type="submit"
                      variant="contained"
                      color="primary"
                    >
                      {t('general.button.submit')}
                    </Button>
                  </Box>

                  <ScrollToError />
                </Form>
              ),
              [
                values.isPartnerInformation,
                values.preferredContactMethod,
                values.isWorkingWithIVF,
                values.referredFrom,
              ],
            );
          }}
        </Formik>
      </Card>
    </>
  );
};
