import { LightLayout } from '@app/layouts';
import { SignUpInfo } from '@app/auth/components/SignUpInfo';
import { Typography, Link } from '@mui/material';
import { Trans, useTranslation } from 'react-i18next';

export const SurrogateDisqualifiedPage = () => {
  const { t } = useTranslation('common');

  return (
    <LightLayout>
      <SignUpInfo
        title={t('auth.page.signUp.block.title')}
        onClick={() => {
          window.location.href = 'https://www.internationalsurrogacycenter.com/contact-us/';
        }}
        buttonText={t('auth.page.signUp.block.button')}
      >
        <Typography variant="subtitle1" paragraph sx={{ mb: 4 }}>
          {t('auth.page.signUp.block.text1')}
        </Typography>
        <Typography variant="subtitle1" paragraph sx={{ mb: 4 }}>
          <Trans
            i18nKey={t('auth.page.signUp.block.text2')}
            components={{
              requirements: (
                <Link
                  href="https://www.internationalsurrogacycenter.com/requirements-to-become-a-surrogate/"
                  target="_blank"
                />
              ),
            }}
          />
        </Typography>
      </SignUpInfo>
    </LightLayout>
  );
};
