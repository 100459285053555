import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { useQuery } from '@apollo/client';

import { File as FileDto } from '@app/file/types';
import { apolloClient } from '@app/query/configs';
import { UploadFiles } from '@app/file/components/UploadFiles';

import { Application } from '../../types/Application';
import { APPLICATION_UPDATE_ATTACH_PHOTOS, applicationGetOne } from '../../gql';

export interface UploadFormProps {
  readonly applicationId: number;
}

export const UploadForm = ({ applicationId }: UploadFormProps) => {
  const { t } = useTranslation('common');

  const { data, loading, refetch } = useQuery<{ applicationGetOne: Application }>(applicationGetOne(['photos.name']), {
    variables: { id: applicationId },
  });

  const maxFiles = 8;
  const uploadedFiles = data?.applicationGetOne?.photos || [];

  const handleChange = useCallback(async (photos: FileDto[]) => {
    await apolloClient.mutate<Record<any, any>>({
      mutation: APPLICATION_UPDATE_ATTACH_PHOTOS,
      variables: {
        input: { id: applicationId, photos: photos.map(({ id }) => id) },
      },
    });
  }, []);

  const handleDeleted = useCallback(() => {
    refetch();
  }, [refetch]);

  return (
    <UploadFiles
      name="uploadPhoto"
      type="applicationPhoto"
      maxFiles={maxFiles}
      value={uploadedFiles}
      isLoading={loading}
      onChange={handleChange}
      onDeleted={handleDeleted}
      label={t('application.field.photos.label')}
      description={t('application.field.photos.description')}
    />
  );
};
