import dayJs from 'dayjs';

import { DynamicFormConfigSurvey, DynamicFormElementType, DynamicFormElementWidthMode } from '@app/dynamic';

import {
  ApplicationPdf,
  ApplicationSurveyEnum,
  ExperienceDuringPregnancyEnum,
  numberOfAbortionsOptions,
  numberOfMiscarriagesOptions,
} from '../constants';
import cardImage from '../images/card-application-pregnancy-history.jpg';

export const pregnancyHistory: DynamicFormConfigSurvey<ApplicationSurveyEnum> = {
  name: ApplicationSurveyEnum.pregnancyHistory,
  meta: {
    cardImage,
    dependOnFields: ['status', 'firstName', 'lastName'],
  },
  pages: [
    {
      name: `${ApplicationSurveyEnum.pregnancyHistory}.information`,
      information: true,
      elements: [
        {
          name: `${ApplicationSurveyEnum.pregnancyHistory}.information`,
          type: DynamicFormElementType.information,
          widthMode: DynamicFormElementWidthMode.full,
          props: {
            variables: {
              time: '5',
            },
            template: [['description.item1', 'description.item2', 'description.item3']],
          },
        },
      ],
    },
    {
      name: 'pregnanciesDoctorBedRest',
      elements: [
        {
          noLabel: true,
          name: 'pregnanciesDoctorBedRest',
          type: DynamicFormElementType.booleanRadio,
          widthMode: DynamicFormElementWidthMode.full,
          meta: {
            pdf: {
              [ApplicationPdf.general]: true,
            },
          },
        },
        {
          name: 'pregnanciesDoctorBedRestDetails',
          alias: 'pleaseExplain',
          type: DynamicFormElementType.text,
          visibleIf: ({ pregnanciesDoctorBedRest }) => pregnanciesDoctorBedRest,
          widthMode: DynamicFormElementWidthMode.full,
          meta: {
            pdf: {
              [ApplicationPdf.general]: true,
            },
          },
          props: {
            multiline: true,
            length: 1000,
          },
        },
      ],
    },
    {
      name: 'haveMedicalPregnant',
      elements: [
        {
          noLabel: true,
          name: 'haveMedicalPregnant',
          type: DynamicFormElementType.booleanRadio,
          widthMode: DynamicFormElementWidthMode.full,
          meta: {
            pdf: {
              [ApplicationPdf.general]: true,
            },
          },
        },
        {
          name: 'haveMedicalPregnantDetails',
          alias: 'pleaseExplain',
          type: DynamicFormElementType.text,
          visibleIf: ({ haveMedicalPregnant }) => haveMedicalPregnant,
          widthMode: DynamicFormElementWidthMode.full,
          meta: {
            pdf: {
              [ApplicationPdf.general]: true,
            },
          },
          props: {
            multiline: true,
            length: 1000,
          },
        },
      ],
    },
    {
      name: 'childrenBornMedicalAttention',
      elements: [
        {
          noLabel: true,
          name: 'childrenBornMedicalAttention',
          type: DynamicFormElementType.booleanRadio,
          widthMode: DynamicFormElementWidthMode.full,
          meta: {
            pdf: {
              [ApplicationPdf.general]: true,
            },
          },
        },
        {
          name: 'childrenBornMedicalAttentionDetails',
          alias: 'pleaseExplain',
          type: DynamicFormElementType.text,
          visibleIf: ({ childrenBornMedicalAttention }) => childrenBornMedicalAttention,
          widthMode: DynamicFormElementWidthMode.full,
          meta: {
            pdf: {
              [ApplicationPdf.general]: true,
            },
          },
          props: {
            multiline: true,
            length: 1000,
          },
        },
      ],
    },
    {
      name: 'experienceDuringPregnancy',
      showDescription: true,
      elements: [
        {
          noLabel: true,
          name: 'experienceDuringPregnancy',
          type: DynamicFormElementType.enumCheckbox,
          widthMode: DynamicFormElementWidthMode.full,
          meta: {
            pdf: {
              [ApplicationPdf.general]: true,
            },
          },
          props: {
            enum: ExperienceDuringPregnancyEnum,
            none: ExperienceDuringPregnancyEnum.none,
          },
        },
        {
          name: 'experienceDuringPregnancyDetails',
          alias: 'pleaseExplain',
          type: DynamicFormElementType.text,
          visibleIf: ({ experienceDuringPregnancy }) =>
            experienceDuringPregnancy?.length &&
            !experienceDuringPregnancy?.includes(ExperienceDuringPregnancyEnum.none),
          widthMode: DynamicFormElementWidthMode.full,
          meta: {
            pdf: {
              [ApplicationPdf.general]: true,
            },
          },
          props: {
            multiline: true,
            length: 1000,
          },
        },
      ],
    },
    {
      name: 'hasMiscarriages',
      elements: [
        {
          noLabel: true,
          name: 'hasMiscarriages',
          type: DynamicFormElementType.booleanRadio,
          widthMode: DynamicFormElementWidthMode.full,
          meta: {
            pdf: {
              [ApplicationPdf.general]: true,
            },
          },
        },
        {
          name: 'numberOfMiscarriages',
          type: DynamicFormElementType.text,
          widthMode: DynamicFormElementWidthMode.full,
          meta: {
            pdf: {
              [ApplicationPdf.general]: true,
            },
          },
          visibleIf: ({ hasMiscarriages }) => hasMiscarriages,
          props: {
            select: true,
            options: numberOfMiscarriagesOptions,
          },
        },
        {
          name: 'numberOfMiscarriagesDetails',
          showDescription: true,
          type: DynamicFormElementType.text,
          visibleIf: ({ hasMiscarriages, numberOfMiscarriages }) => hasMiscarriages && Boolean(numberOfMiscarriages),
          widthMode: DynamicFormElementWidthMode.full,
          meta: {
            pdf: {
              [ApplicationPdf.general]: true,
            },
          },
          props: {
            multiline: true,
            length: 1000,
          },
        },
      ],
    },
    {
      name: 'hasAbortions',
      elements: [
        {
          noLabel: true,
          name: 'hasAbortions',
          type: DynamicFormElementType.booleanRadio,
          widthMode: DynamicFormElementWidthMode.full,
          meta: {
            pdf: {
              [ApplicationPdf.general]: true,
            },
          },
        },
        {
          name: 'numberOfAbortions',
          type: DynamicFormElementType.text,
          widthMode: DynamicFormElementWidthMode.full,
          meta: {
            pdf: {
              [ApplicationPdf.general]: true,
            },
          },
          visibleIf: ({ hasAbortions }) => hasAbortions,
          props: {
            select: true,
            options: numberOfAbortionsOptions,
          },
        },
        {
          name: 'abortionsYear',
          showDescription: true,
          type: DynamicFormElementType.text,
          visibleIf: ({ hasAbortions, numberOfAbortions }) => hasAbortions && Boolean(numberOfAbortions),
          widthMode: DynamicFormElementWidthMode.full,
          meta: {
            pdf: {
              [ApplicationPdf.general]: true,
            },
          },
          props: {
            multiline: true,
            length: 100,
          },
        },
        {
          name: 'abortionsMedicalDiagnosis',
          type: DynamicFormElementType.booleanRadio,
          visibleIf: ({ hasAbortions, numberOfAbortions }) => hasAbortions && Boolean(numberOfAbortions),
          widthMode: DynamicFormElementWidthMode.full,
          meta: {
            pdf: {
              [ApplicationPdf.general]: true,
            },
          },
        },
        {
          name: 'abortionsMedicalDiagnosisDetails',
          alias: 'pleaseExplain',
          type: DynamicFormElementType.text,
          visibleIf: ({ hasAbortions, abortionsMedicalDiagnosis, numberOfAbortions }) =>
            hasAbortions && Boolean(numberOfAbortions) && abortionsMedicalDiagnosis,
          widthMode: DynamicFormElementWidthMode.full,
          meta: {
            pdf: {
              [ApplicationPdf.general]: true,
            },
          },
          props: {
            multiline: true,
            length: 1000,
          },
        },
      ],
    },
    {
      name: 'beenSurrogate',
      elements: [
        {
          noLabel: true,
          name: 'beenSurrogate',
          type: DynamicFormElementType.booleanRadio,
          widthMode: DynamicFormElementWidthMode.full,
          meta: {
            pdf: {
              [ApplicationPdf.general]: true,
            },
          },
        },
        {
          name: 'hadEmbryoTransfer',
          type: DynamicFormElementType.booleanRadio,
          visibleIf: ({ beenSurrogate }) => beenSurrogate,
          widthMode: DynamicFormElementWidthMode.full,
          meta: {
            pdf: {
              [ApplicationPdf.general]: true,
            },
          },
        },
        {
          name: 'hadEmbryoTransferDetails',
          showDescription: true,
          type: DynamicFormElementType.text,
          visibleIf: ({ hadEmbryoTransfer, beenSurrogate }) => hadEmbryoTransfer === false && beenSurrogate,
          widthMode: DynamicFormElementWidthMode.full,
          meta: {
            pdf: {
              [ApplicationPdf.general]: true,
            },
          },
          props: {
            multiline: true,
            length: 1000,
          },
        },
        {
          name: 'numberOfSurrogateDeliveries',
          type: DynamicFormElementType.text,
          visibleIf: ({ isPdf }) => isPdf,
          widthMode: DynamicFormElementWidthMode.full,
          meta: {
            pdf: {
              [ApplicationPdf.general]: true,
            },
          },
        },
      ],
    },
    {
      name: 'currentlyBreastfeeding',
      elements: [
        {
          noLabel: true,
          name: 'currentlyBreastfeeding',
          type: DynamicFormElementType.booleanRadio,
          widthMode: DynamicFormElementWidthMode.full,
          meta: {
            pdf: {
              [ApplicationPdf.general]: true,
            },
          },
        },
        {
          name: 'breastfeedingEndDate',
          type: DynamicFormElementType.date,
          visibleIf: ({ currentlyBreastfeeding }) => currentlyBreastfeeding,
          widthMode: DynamicFormElementWidthMode.full,
          meta: {
            pdf: {
              [ApplicationPdf.general]: true,
            },
          },
          props: {
            openTo: 'year',
            minDate: dayJs().subtract(1, 'years'),
            maxDate: dayJs().add(10, 'years'),
            views: ['year', 'month', 'day'],
          },
        },
      ],
    },
    {
      name: 'moodDuringPregnancy',
      elements: [
        {
          noLabel: true,
          name: 'moodDuringPregnancy',
          type: DynamicFormElementType.text,
          widthMode: DynamicFormElementWidthMode.full,
          meta: {
            pdf: {
              [ApplicationPdf.general]: true,
            },
          },
          props: {
            multiline: true,
            length: 1000,
          },
        },
        {
          name: 'feelsWhenGiveBirthBaby',
          showDescription: true,
          type: DynamicFormElementType.text,
          widthMode: DynamicFormElementWidthMode.full,
          meta: {
            pdf: {
              [ApplicationPdf.general]: true,
            },
          },
          props: {
            multiline: true,
            length: 1000,
          },
        },
      ],
    },
  ],
};
