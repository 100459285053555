import {
  DynamicFormArrayType,
  DynamicFormConfigSurvey,
  DynamicFormElementType,
  DynamicFormElementWidthMode,
} from '@app/dynamic';
import { inArray } from '@app/dynamic/utils';

import {
  ApplicationPdf,
  ApplicationSurveyEnum,
  BloodTypeEnum,
  BirthControlMethodEnum,
  numberOfMedicationOptions,
  ImmunizedHepatitisBEnum,
  ConsentGetCOVIDVaccineEnum,
  HaveAlcoholNotPregnantEnum,
  DiagnosisEnum,
} from '../constants';
import cardImage from '../images/card-application-health.jpg';

export const healthInfo: DynamicFormConfigSurvey<ApplicationSurveyEnum> = {
  name: ApplicationSurveyEnum.healthInfo,
  meta: {
    cardImage,
    dependOnFields: ['status', 'firstName', 'lastName'],
  },
  pages: [
    {
      name: `${ApplicationSurveyEnum.healthInfo}.information`,
      information: true,
      elements: [
        {
          name: `${ApplicationSurveyEnum.healthInfo}.information`,
          type: DynamicFormElementType.information,
          widthMode: DynamicFormElementWidthMode.full,
          props: {
            variables: {
              time: '15-20',
            },
            template: [['description.item1', 'description.item2', 'description.item3']],
          },
        },
      ],
    },
    {
      name: `${ApplicationSurveyEnum.healthInfo}.informationPart1`,
      information: true,
      elements: [
        {
          name: `${ApplicationSurveyEnum.healthInfo}.informationPart1`,
          type: DynamicFormElementType.information,
          widthMode: DynamicFormElementWidthMode.full,
          props: {
            template: ['header'],
          },
        },
      ],
    },
    {
      name: 'bloodType',
      showDescription: true,
      elements: [
        {
          noLabel: true,
          name: 'bloodType',
          type: DynamicFormElementType.enumSelect,
          widthMode: DynamicFormElementWidthMode.full,
          meta: {
            pdf: {
              [ApplicationPdf.general]: true,
            },
          },
          props: {
            enum: BloodTypeEnum,
          },
        },
      ],
    },
    {
      name: 'birthControlMethod',
      elements: [
        {
          noLabel: true,
          name: 'birthControlMethod',
          type: DynamicFormElementType.enumSelect,
          widthMode: DynamicFormElementWidthMode.full,
          meta: {
            adminField: 'adminBirthControlMethod',
            pdf: {
              [ApplicationPdf.general]: true,
            },
          },
          props: {
            enum: BirthControlMethodEnum,
          },
        },
        {
          name: 'adminBirthControlMethod',
          optional: true,
          visibleIf: ({ isAdmin, isPdf }) => isAdmin || isPdf,
          type: DynamicFormElementType.text,
          widthMode: DynamicFormElementWidthMode.full,
          meta: {
            pdf: {
              [ApplicationPdf.general]: true,
            },
          },
          props: {
            length: 100,
            multiline: true,
          },
        },
      ],
    },
    {
      name: 'yearRecentPapSmear',
      elements: [
        {
          noLabel: true,
          name: 'yearRecentPapSmear',
          type: DynamicFormElementType.text,
          widthMode: DynamicFormElementWidthMode.full,
          meta: {
            pdf: {
              [ApplicationPdf.general]: true,
            },
          },
          props: {
            length: 10,
          },
        },
        {
          name: 'haveAbnormalPapSmearResult',
          type: DynamicFormElementType.booleanRadio,
          widthMode: DynamicFormElementWidthMode.full,
          meta: {
            pdf: {
              [ApplicationPdf.general]: true,
            },
          },
        },
        {
          name: 'yearAndProcedurePapSmear',
          type: DynamicFormElementType.text,
          visibleIf: ({ haveAbnormalPapSmearResult }) => haveAbnormalPapSmearResult,
          widthMode: DynamicFormElementWidthMode.full,
          meta: {
            pdf: {
              [ApplicationPdf.general]: true,
            },
          },
          props: {
            multiline: true,
            length: 50,
          },
        },
      ],
    },
    {
      name: 'aroundSecondhandSmoke',
      showDescription: true,
      elements: [
        {
          noLabel: true,
          name: 'aroundSecondhandSmoke',
          type: DynamicFormElementType.booleanRadio,
          widthMode: DynamicFormElementWidthMode.full,
          meta: {
            pdf: {
              [ApplicationPdf.general]: true,
            },
          },
        },
        {
          name: 'notBeAroundSecondhandSmoke',
          type: DynamicFormElementType.booleanRadio,
          visibleIf: ({ aroundSecondhandSmoke }) => aroundSecondhandSmoke,
          widthMode: DynamicFormElementWidthMode.full,
          meta: {
            pdf: {
              [ApplicationPdf.general]: true,
            },
          },
        },
      ],
    },
    {
      name: 'isIllicitDrugs',
      elements: [
        {
          noLabel: true,
          name: 'isIllicitDrugs',
          type: DynamicFormElementType.booleanRadio,
          widthMode: DynamicFormElementWidthMode.full,
          meta: {
            pdf: {
              [ApplicationPdf.general]: true,
            },
          },
        },
        {
          name: 'isIllicitDrugsDetails',
          alias: 'pleaseExplain',
          type: DynamicFormElementType.text,
          visibleIf: ({ isIllicitDrugs }) => isIllicitDrugs,
          widthMode: DynamicFormElementWidthMode.full,
          meta: {
            pdf: {
              [ApplicationPdf.general]: true,
            },
          },
          props: {
            multiline: true,
            length: 1000,
          },
        },
      ],
    },
    {
      name: 'healthConcernsBeAwareOf',
      elements: [
        {
          noLabel: true,
          name: 'healthConcernsBeAwareOf',
          type: DynamicFormElementType.booleanRadio,
          widthMode: DynamicFormElementWidthMode.full,
          meta: {
            pdf: {
              [ApplicationPdf.general]: true,
            },
          },
        },
        {
          name: 'healthConcernsExplain',
          type: DynamicFormElementType.text,
          visibleIf: ({ healthConcernsBeAwareOf }) => healthConcernsBeAwareOf,
          widthMode: DynamicFormElementWidthMode.full,
          meta: {
            pdf: {
              [ApplicationPdf.general]: true,
            },
          },
          props: {
            multiline: true,
            length: 1000,
          },
        },
      ],
    },
    {
      name: 'usePrescriptionMedication',
      showDescription: true,
      elements: [
        {
          noLabel: true,
          name: 'usePrescriptionMedication',
          type: DynamicFormElementType.booleanRadio,
          widthMode: DynamicFormElementWidthMode.full,
          meta: {
            pdf: {
              [ApplicationPdf.general]: true,
            },
          },
        },
        {
          name: 'numberOfMedication',
          type: DynamicFormElementType.text,
          visibleIf: ({ usePrescriptionMedication }) => usePrescriptionMedication,
          widthMode: DynamicFormElementWidthMode.full,
          meta: {
            pdf: {
              [ApplicationPdf.general]: true,
            },
          },
          props: {
            select: true,
            options: numberOfMedicationOptions,
          },
        },
      ],
    },
    {
      name: 'medications',
      array: {
        type: DynamicFormArrayType.dependent,
        length: ({ usePrescriptionMedication, numberOfMedication }) =>
          usePrescriptionMedication ? numberOfMedication || 0 : 0,
      },
      meta: {
        json: true,
      },
      elements: [
        {
          name: 'medications.{index}.name',
          alias: 'medications.name',
          type: DynamicFormElementType.text,
          widthMode: DynamicFormElementWidthMode.full,
          meta: {
            pdf: {
              [ApplicationPdf.general]: true,
            },
          },
          props: {
            multiline: true,
            length: 100,
          },
        },
        {
          name: 'medications.{index}.for',
          alias: 'medications.for',
          type: DynamicFormElementType.text,
          widthMode: DynamicFormElementWidthMode.full,
          meta: {
            pdf: {
              [ApplicationPdf.general]: true,
            },
          },
          props: {
            multiline: true,
            length: 500,
          },
        },
        {
          name: 'medications.{index}.stillTaking',
          alias: 'medications.stillTaking',
          type: DynamicFormElementType.booleanRadio,
          meta: {
            pdf: {
              [ApplicationPdf.general]: true,
            },
          },
        },
        {
          name: 'medications.{index}.stopTaking',
          alias: 'medications.stopTaking',
          type: DynamicFormElementType.date,
          visibleIf: inArray(({ stillTaking }) => stillTaking === false),
          meta: {
            pdf: {
              [ApplicationPdf.general]: true,
            },
          },
          props: {
            views: ['year', 'month', 'day'],
          },
        },
      ],
    },
    {
      name: 'allergicInfo',
      showDescription: true,
      elements: [
        {
          noLabel: true,
          name: 'allergicInfo',
          type: DynamicFormElementType.booleanRadio,
          widthMode: DynamicFormElementWidthMode.full,
          meta: {
            pdf: {
              [ApplicationPdf.general]: true,
            },
          },
        },
        {
          name: 'allergicInfoDetails',
          type: DynamicFormElementType.text,
          visibleIf: ({ allergicInfo }) => allergicInfo,
          widthMode: DynamicFormElementWidthMode.full,
          meta: {
            pdf: {
              [ApplicationPdf.general]: true,
            },
          },
          props: {
            multiline: true,
            length: 100,
          },
        },
      ],
    },
    {
      name: 'doctorVisit',
      showDescription: true,
      elements: [
        {
          noLabel: true,
          name: 'doctorVisit',
          type: DynamicFormElementType.booleanRadio,
          widthMode: DynamicFormElementWidthMode.full,
          meta: {
            pdf: {
              [ApplicationPdf.general]: true,
            },
          },
        },
        {
          name: 'doctorVisitDetails',
          type: DynamicFormElementType.text,
          visibleIf: ({ doctorVisit }) => doctorVisit,
          widthMode: DynamicFormElementWidthMode.full,
          meta: {
            pdf: {
              [ApplicationPdf.general]: true,
            },
          },
          props: {
            multiline: true,
            length: 300,
          },
        },
      ],
    },
    {
      name: 'hospitalization',
      elements: [
        {
          noLabel: true,
          name: 'hospitalization',
          type: DynamicFormElementType.booleanRadio,
          widthMode: DynamicFormElementWidthMode.full,
          meta: {
            pdf: {
              [ApplicationPdf.general]: true,
            },
          },
        },
        {
          name: 'hospitalizationDetails',
          type: DynamicFormElementType.text,
          visibleIf: ({ hospitalization }) => hospitalization,
          widthMode: DynamicFormElementWidthMode.full,
          meta: {
            pdf: {
              [ApplicationPdf.general]: true,
            },
          },
          props: {
            multiline: true,
            length: 300,
          },
        },
      ],
    },
    {
      name: 'eatingDisorder',
      elements: [
        {
          noLabel: true,
          name: 'eatingDisorder',
          type: DynamicFormElementType.booleanRadio,
          widthMode: DynamicFormElementWidthMode.full,
          meta: {
            pdf: {
              [ApplicationPdf.general]: true,
            },
          },
        },
        {
          name: 'eatingDisorderDetails',
          alias: 'pleaseExplain',
          type: DynamicFormElementType.text,
          visibleIf: ({ eatingDisorder }) => eatingDisorder,
          widthMode: DynamicFormElementWidthMode.full,
          meta: {
            pdf: {
              [ApplicationPdf.general]: true,
            },
          },
          props: {
            multiline: true,
            length: 300,
          },
        },
      ],
    },
    {
      name: 'isAutismSpectrumDisorder',
      elements: [
        {
          noLabel: true,
          name: 'isAutismSpectrumDisorder',
          type: DynamicFormElementType.booleanRadio,
          widthMode: DynamicFormElementWidthMode.full,
          meta: {
            pdf: {
              [ApplicationPdf.general]: true,
            },
          },
        },
        {
          name: 'isAutismSpectrumDisorderDetails',
          alias: 'pleaseExplain',
          type: DynamicFormElementType.text,
          visibleIf: ({ isAutismSpectrumDisorder }) => isAutismSpectrumDisorder,
          widthMode: DynamicFormElementWidthMode.full,
          meta: {
            pdf: {
              [ApplicationPdf.general]: true,
            },
          },
          props: {
            multiline: true,
            length: 1000,
          },
        },
      ],
    },
    {
      name: 'psychiatristVisit',
      elements: [
        {
          noLabel: true,
          name: 'psychiatristVisit',
          type: DynamicFormElementType.booleanRadio,
          widthMode: DynamicFormElementWidthMode.full,
          meta: {
            pdf: {
              [ApplicationPdf.general]: true,
            },
          },
        },
        {
          name: 'psychiatristVisitDetails',
          showDescription: true,
          type: DynamicFormElementType.text,
          visibleIf: ({ psychiatristVisit }) => psychiatristVisit,
          widthMode: DynamicFormElementWidthMode.full,
          meta: {
            pdf: {
              [ApplicationPdf.general]: true,
            },
          },
          props: {
            multiline: true,
            length: 1000,
          },
        },
      ],
    },
    {
      name: 'hospitalizationPsychiatricReasons',
      showDescription: true,
      elements: [
        {
          noLabel: true,
          name: 'hospitalizationPsychiatricReasons',
          type: DynamicFormElementType.booleanRadio,
          widthMode: DynamicFormElementWidthMode.full,
          meta: {
            pdf: {
              [ApplicationPdf.general]: true,
            },
          },
        },
      ],
    },
    {
      name: 'beenAbused',
      showDescription: true,
      elements: [
        {
          noLabel: true,
          name: 'beenAbused',
          type: DynamicFormElementType.booleanRadio,
          widthMode: DynamicFormElementWidthMode.full,
          meta: {
            pdf: {
              [ApplicationPdf.general]: true,
            },
          },
        },
      ],
    },
    {
      name: 'pelvicInflammatoryDisease',
      elements: [
        {
          noLabel: true,
          name: 'pelvicInflammatoryDisease',
          type: DynamicFormElementType.booleanRadio,
          widthMode: DynamicFormElementWidthMode.full,
          meta: {
            pdf: {
              [ApplicationPdf.general]: true,
            },
          },
        },
        {
          name: 'pelvicInflammatoryDiseaseDetails',
          alias: 'pleaseExplain',
          type: DynamicFormElementType.text,
          visibleIf: ({ pelvicInflammatoryDisease }) => pelvicInflammatoryDisease,
          widthMode: DynamicFormElementWidthMode.full,
          meta: {
            pdf: {
              [ApplicationPdf.general]: true,
            },
          },
          props: {
            multiline: true,
            length: 300,
          },
        },
      ],
    },
    {
      name: 'diagnosedHIVOrAIDS',
      elements: [
        {
          noLabel: true,
          name: 'diagnosedHIVOrAIDS',
          type: DynamicFormElementType.booleanRadio,
          widthMode: DynamicFormElementWidthMode.full,
          meta: {
            pdf: {
              [ApplicationPdf.general]: true,
            },
          },
        },
        {
          name: 'diagnosedHIVOrAIDSDetails',
          alias: 'pleaseExplain',
          type: DynamicFormElementType.text,
          visibleIf: ({ diagnosedHIVOrAIDS }) => diagnosedHIVOrAIDS,
          widthMode: DynamicFormElementWidthMode.full,
          meta: {
            pdf: {
              [ApplicationPdf.general]: true,
            },
          },
          props: {
            multiline: true,
            length: 300,
          },
        },
      ],
    },
    {
      name: 'immunizedHepatitisB',
      showDescription: true,
      elements: [
        {
          noLabel: true,
          name: 'immunizedHepatitisB',
          type: DynamicFormElementType.enumRadio,
          widthMode: DynamicFormElementWidthMode.full,
          meta: {
            pdf: {
              [ApplicationPdf.general]: true,
            },
          },
          props: {
            enum: ImmunizedHepatitisBEnum,
          },
        },
        {
          name: 'immunizedHepatitisBProof',
          showDescription: true,
          type: DynamicFormElementType.booleanRadio,
          widthMode: DynamicFormElementWidthMode.full,
          meta: {
            pdf: {
              [ApplicationPdf.general]: true,
            },
          },
        },
      ],
    },
    {
      name: 'sexualPartnersWithHepatitis',
      elements: [
        {
          noLabel: true,
          name: 'sexualPartnersWithHepatitis',
          type: DynamicFormElementType.booleanRadio,
          widthMode: DynamicFormElementWidthMode.full,
          meta: {
            pdf: {
              [ApplicationPdf.general]: true,
            },
          },
        },
        {
          name: 'sexualPartnersWithHepatitisDetails',
          alias: 'pleaseExplain',
          type: DynamicFormElementType.text,
          visibleIf: ({ sexualPartnersWithHepatitis }) => sexualPartnersWithHepatitis,
          widthMode: DynamicFormElementWidthMode.full,
          meta: {
            pdf: {
              [ApplicationPdf.general]: true,
            },
          },
          props: {
            multiline: true,
            length: 300,
          },
        },
      ],
    },
    {
      name: 'receivedCOVIDVaccination',
      elements: [
        {
          noLabel: true,
          name: 'receivedCOVIDVaccination',
          type: DynamicFormElementType.booleanRadio,
          widthMode: DynamicFormElementWidthMode.full,
          meta: {
            pdf: {
              [ApplicationPdf.general]: true,
            },
          },
        },
        {
          name: 'consentGetCOVIDVaccine',
          type: DynamicFormElementType.enumRadio,
          visibleIf: ({ receivedCOVIDVaccination }) => receivedCOVIDVaccination === false,
          widthMode: DynamicFormElementWidthMode.full,
          meta: {
            pdf: {
              [ApplicationPdf.general]: true,
            },
          },
          props: {
            enum: ConsentGetCOVIDVaccineEnum,
          },
        },
      ],
    },
    {
      name: 'regularMenstrualCycles',
      elements: [
        {
          noLabel: true,
          name: 'regularMenstrualCycles',
          type: DynamicFormElementType.booleanRadio,
          widthMode: DynamicFormElementWidthMode.full,
          meta: {
            pdf: {
              [ApplicationPdf.general]: true,
            },
          },
        },
        {
          name: 'daysBetweenMenstrualCycles',
          type: DynamicFormElementType.text,
          showDescription: true,
          visibleIf: ({ regularMenstrualCycles }) => regularMenstrualCycles,
          widthMode: DynamicFormElementWidthMode.full,
          meta: {
            pdf: {
              [ApplicationPdf.general]: true,
            },
          },
          props: {
            length: 20,
          },
        },
        {
          name: 'daysLastMenstrualCyclesPeriod',
          type: DynamicFormElementType.text,
          showDescription: true,
          visibleIf: ({ regularMenstrualCycles }) => regularMenstrualCycles,
          widthMode: DynamicFormElementWidthMode.full,
          meta: {
            pdf: {
              [ApplicationPdf.general]: true,
            },
          },
          props: {
            length: 20,
          },
        },
      ],
    },
    {
      name: 'haveAlcoholNotPregnant',
      elements: [
        {
          noLabel: true,
          name: 'haveAlcoholNotPregnant',
          type: DynamicFormElementType.enumSelect,
          widthMode: DynamicFormElementWidthMode.full,
          meta: {
            pdf: {
              [ApplicationPdf.general]: true,
            },
          },
          props: {
            enum: HaveAlcoholNotPregnantEnum,
          },
        },
      ],
    },
    {
      name: `${ApplicationSurveyEnum.healthInfo}.informationPart2`,
      information: true,
      elements: [
        {
          name: `${ApplicationSurveyEnum.healthInfo}.informationPart2`,
          type: DynamicFormElementType.information,
          widthMode: DynamicFormElementWidthMode.full,
          props: {
            template: [['description.item1', 'description.item2']],
          },
        },
      ],
    },
    {
      name: 'yourDiagnosis',
      elements: [
        {
          name: 'yourDiagnosis',
          type: DynamicFormElementType.toggleButtonGroup,
          widthMode: DynamicFormElementWidthMode.full,
          meta: {
            json: true,
            pdf: {
              [ApplicationPdf.general]: true,
            },
          },
          props: {
            options: Object.values(DiagnosisEnum),
          },
        },
        {
          name: 'yourDiagnosisDetails',
          type: DynamicFormElementType.text,
          visibleIf: ({ yourDiagnosis }) => yourDiagnosis && Object.values(yourDiagnosis).some(Boolean),
          widthMode: DynamicFormElementWidthMode.full,
          meta: {
            pdf: {
              [ApplicationPdf.general]: true,
            },
          },
          props: {
            multiline: true,
            length: 1000,
          },
        },
      ],
    },
  ],
};
