/* eslint-disable */
import { useEffect } from 'react';

declare global {
  interface Window {
    fbq?: (...args: any[]) => void;
    _fbq?: (...args: any[]) => void;
  }
}

export const FacebookPixel = () => {
  useEffect(() => {
    if (process.env.REACT_APP_FACEBOOK_TRACK_ID) {
      (function (f: Window, b: Document, e: string, v: string, n?: any, t?: HTMLScriptElement, s?: HTMLScriptElement) {
        if (f.fbq) return;

        n = f.fbq = function (...args: any[]) {
          n.callMethod ? n.callMethod.apply(n, args) : n.queue.push(args);
        };
        if (!f._fbq) f._fbq = n;
        n.push = n;
        n.loaded = true;
        n.version = '2.0';
        n.queue = [];
        t = b.createElement(e) as HTMLScriptElement;
        t.async = true;
        t.src = v;
        s = b.getElementsByTagName(e)[0] as HTMLScriptElement;
        s.parentNode?.insertBefore(t, s);
      })(window, document, 'script', 'https://connect.facebook.net/en_US/fbevents.js');

      if (window.fbq) {
        window.fbq('init', process.env.REACT_APP_FACEBOOK_TRACK_ID);
        window.fbq('track', 'PageView');
      }
    }
  }, []);

  return (
    <noscript>
      {process.env.REACT_APP_FACEBOOK_TRACK_ID ? (
        <img
          height="1"
          width="1"
          style={{ display: 'none' }}
          alt="Facebook Pixel"
          src={`https://www.facebook.com/tr?id=${process.env.REACT_APP_FACEBOOK_TRACK_ID}&ev=PageView&noscript=1`}
        />
      ) : (
        <></>
      )}
    </noscript>
  );
};
